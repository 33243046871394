<template>
  <div>
    <div class="d-flex justify-content-center py-2">
      <h4 class="d-block text-center">{{ $t('order.test_method').toUpperCase() }}</h4>
    </div>

    <div class="d-flex test-method-tbl-wrapper" style="overflow-x:auto;border-bottom:1px solid #78cdea">
      <div class="col-6 py-2 text-center tbl-item"
           v-for="(testMethodOption, key, index) in testMethodOptions"
           :class="{'': Object.keys(testMethodOptions).length - 1 !== +index}"
           :key="`order_fabric_knit_fabric_test_method_${key}`">
        <div class="w-100" :class="{'text-danger': selectedTestMethodsProxy === undefined ? true: isRequired && selectedTestMethodsProxy.length === 0}">
          {{ testMethodOption }}
        </div>
        <div class="w-100">
          <label class="checkbox checkbox-outline checkbox-outline-2x checkbox-primary p-0 m-0">
            <input type="checkbox"
                   :value="+key"
                   :checked="selectedTestMethodsProxy.includes(+key)"
                   :name="`order_fabric_knit_fabric_test_method_${index}`"
                   v-model="selectedTestMethodsProxy">
            <span style="border-radius: 8px; padding: 0!important; margin:0 !important;"></span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TestMethodRow",
  props: {
    testMethodOptions: {
      required: true,
      type: Object,
    },
    selectedTestMethods:{
      required: true,
    },
    productProcesses: {
      required: true,
    },
    isRequired: {
      required: false,
      default: true,
    }
  },
  computed: {
    selectedTestMethodsProxy: {
      set(value){
        this.$emit('update:selectedTestMethods', value);
        this.$emit('update:productProcesses', value.map(value => {
          return {
            id: value
          };
        }));
      },
      get(){
        if(this.selectedTestMethods === undefined) return [];
        return this.selectedTestMethods;
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/components/_variables.demo.scss';

.checkbox.checkbox-primary > input:checked ~ span {
  background-color: #fff !important;
}

.checkbox.checkbox-outline.checkbox-primary > input:checked ~ span::after {
  border-color: $primary;
}

.checkbox.checkbox-outline.checkbox-primary > input:checked ~ span {
  background-color: #fff;
  border-color: #d6d6e0;
}

.checkbox > span {
  position: relative;
  margin-left: -9px !important;
}
.test-method-tbl-wrapper .tbl-item {
  border-right: 1px solid #78cdea !important;
  font-size: 10px;
  font-weight: bolder;
}
.test-method-tbl-wrapper:last-child {
  border-right: none !important;
}
</style>
