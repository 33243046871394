<template>
  <div class="row export-task-master">
    <div class="col-md-12 px-7">
      <div id="filter-card" class="row py-0 px-0" v-if="filters">
        <div class="col-12 py-4" style="border: 1px solid #0b5a85; background-color: #f3f6f9; border-radius: 12px;">
          <form @submit.prevent="filterResult()">
            <div class="row align-items-end py-0 my-0 mx-0">
              <div class="col-lg-7 col-md-3 col-sm-12 my-2 row px-0 mx-0">
                <input-filter :helper-text="$t('general.search')" :model.sync="filters.search"
                  :placeholder="$t('general.search_filter')" title="general.search"></input-filter>
              </div>
              <div class="col-12 row mx-0 px-0">
                <div class="col-6 my-2">
                  <date-filter :helper-text="$t('general.start_date')" :model.sync="filters.start_date"
                    name="start_time" title="general.start_date">
                  </date-filter>
                </div>
                <div class="col-6 my-2">
                  <date-filter :helper-text="$t('general.end_date')" :model.sync="filters.end_date" name="end_date"
                    title="general.end_date">
                  </date-filter>
                </div>
              </div>

              <div class="col-12 row mx-0 px-0" 
                style="margin: 0 !important;">
                <div class="col-6 my-2">
                  <button class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1"
                    @click="filterResult()">{{
                    $t('general.filter')
                    }}
                  </button>
                </div>
                <div class="col-6 my-2">
                  <button class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1"
                    @click="resetFilters">
                    {{ $t('general.clear') }}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <dashboard-box>
        <template v-slot:preview>
          <div class=" px-5 py-4">
            <template>
              <PackingTaskCard
                  v-if="tasks && tasks.hasOwnProperty('item')"
                  :task="tasks.item"
                  :type="2"
                  @click="onClickCard"
              ></PackingTaskCard>
            </template>

            <template>
              <div v-if="tasks && tasks.hasOwnProperty('item') && !tasks.item.length" class="w-100 mt-5">
                <h3 class="text-center">{{ $t('invoice.no_task') }}</h3>
              </div>
            </template>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {mapGetters, mapMutations} from 'vuex'
import TaskIndexCard from "@/view/pages/samples/layout/task/TaskIndexCard";
import InputFilter from "@/assets/components/filters/InputFilter";
import DateFilter from "@/assets/components/filters/DateFilter";
import Paginate from "@/assets/components/dataTable/Pagination";
import moment from "moment";
import {GET_ITEMS, LOADING} from "@/core/services/store/REST.module";
import {LARAVEL_DATE_FORMAT, MOMENT_SYSTEM_DATE_FORMAT} from "@/core/config/constant";
import PackingTaskCard from "./PackingTaskCard";

import {
  SET_FILTER_TASK,
  FILTER_TASK
} from "@/core/services/store/following-export/following_export.module";
import * as _ from "lodash";
import {FOLLOWING_EXPORT_TASK_INDEX_END_DATE_STORAGE_NAME, FOLLOWING_EXPORT_TASK_INDEX_START_DATE_STORAGE_NAME} from "@/core/storage/storage-names"

export default {
  name: "FollowingExportTasks",
  components: {
    PackingTaskCard,
    DashboardBox,
    TaskIndexCard,
    InputFilter,
    DateFilter,
    Paginate
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      filter: FILTER_TASK
    }),
    filters: {
      get: function () {
        return this.filter;
      },
      set: function (value) {
        this.setFilter(value);
      }
    },
  },
  data() {
    return {
      tasks: [],
    };
  },
  methods: {
    ...mapMutations({
      setFilter: SET_FILTER_TASK,
    }),
    resetFilters() {
      this.filters = {
        search: null,
        per_page: 99999,
        start_date: moment().subtract(30, 'd').format(LARAVEL_DATE_FORMAT),
        end_date: moment().add(1, 'd').format(LARAVEL_DATE_FORMAT),
      }
    },
    filterResult() {
      localStorage.setItem(FOLLOWING_EXPORT_TASK_INDEX_END_DATE_STORAGE_NAME, moment(this.filters.start_date));
      localStorage.setItem(FOLLOWING_EXPORT_TASK_INDEX_START_DATE_STORAGE_NAME, moment(this.filters.end_date));

      let self = this;
      let filters = _.cloneDeep(this.filters);
      this.$set(filters, 'start_date', moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT));
      this.$set(filters, 'end_date', moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT));
      this.$store.dispatch(GET_ITEMS, {
        url: 'api/exports/packing-task-list',
        acceptPromise: true,
        filters: filters,
        showLoading: true,
      }).then((result) => {
        if (result.status) {
          let items = result.data;
          let results = [];
          items.forEach(item => {
            let inPackage = 0;
            let boxQuantity = 0;
            let amount = 0;
            let totalAmount = 0;
            item.data.forEach(subItem => {
              inPackage += Number(subItem.in_package) || 0;
              boxQuantity += Number(subItem.box_quantity) || 0;
              amount += Number(subItem.amount) | 0;
              totalAmount +=  Number(subItem.box_quantity) * Number(subItem.in_package) + Number(subItem.broken_parcel)
              //eğer kırık koli 1 den büyükse ekleme yapılıyor
              if(subItem.broken_parcel>0){
                boxQuantity +=1;
              }
            })
            item.orderable.order_size_and_colors.forEach(color => {
              colors.push(color.full_name)
            });
            results.push({
              created_at: (item.created_at) ? moment(item.created_at, LARAVEL_DATE_FORMAT).format(MOMENT_SYSTEM_DATE_FORMAT) : '',
              order_number: item.orderable.order_number,
              po_number: item.orderable.po_number,
              model: item.orderable.model,
              in_package: inPackage,
              box_quantity: boxQuantity,
              amount: amount,
              total_amount: totalAmount,
              supplier_company_name:item.supplier_company.name,
              user:item.user?item.user.name:item.supplier_company.name,
              order_size_and_colors:colors.toString(),
              id: item.id,
            });
          })
          self.tasks = {
            count: 1,
            item: results,
          }
        }
      })
    },
    onClickCard(payload) {
      this.$router.push({name: 'following_export.packing_list.index', params: {packing_id: payload.task.id}})
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t('menu.following_export'), route: "following_production.index"},
      {title: this.$t('general.tasks')}
    ]);

    if (!this.filter) {
      this.resetFilters();
    }
    this.filters.end_date = localStorage.hasOwnProperty(FOLLOWING_EXPORT_TASK_INDEX_START_DATE_STORAGE_NAME)
      ? moment(localStorage.getItem(FOLLOWING_EXPORT_TASK_INDEX_START_DATE_STORAGE_NAME))
      : moment().add(1, 'd').format(LARAVEL_DATE_FORMAT);

    this.filters.start_date = localStorage.hasOwnProperty(FOLLOWING_EXPORT_TASK_INDEX_END_DATE_STORAGE_NAME)
      ? moment(localStorage.getItem(FOLLOWING_EXPORT_TASK_INDEX_END_DATE_STORAGE_NAME))
      : moment().subtract(30, 'd').format(LARAVEL_DATE_FORMAT);

    this.filterResult();
  }
}
</script>

<style lang="scss">
/*Mobile Start*/
@import "@/assets/sass/mobile/following-export/followingExportTasks.scss";
/*Mobile End*/
</style>
