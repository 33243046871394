<template>
  <div class="px-5">

    <!--    <button v-if="didChangeProcessingType" type="button" class="btn btn-danger btn-sm" @click="deleteInstruction">Eski talimatı iptal et</button>-->

    <template v-for="(currentDetail, index, i) in modelProxy">
      <div :class="{'mt-5': +i !== 0}"
      :ref="`paint_request_${currentDetail.id}`"
      :id="`paint_request_${currentDetail.id}`"
      >
      <div class="d-flex navy-blue-border-bottom-1px px-2 py-2">
        <span class="h6 text-sm mr-2">{{$t('general.created_at')}}:</span><span class="text-sm">{{currentDetail.created_at?moment(currentDetail.created_at).format('DD.MM.YYYY hh:mm:ss'):moment().format('DD.MM.YYYY hh:mm:ss')}}</span>
        
        <span class="mx-1 h25"> / </span>
        <span class="h6 text-sm mr-2">
          {{$t('general.updated_date')}}:</span><span class="text-sm"> {{currentDetail.updated_at?moment(currentDetail.updated_at).format('DD.MM.YYYY hh:mm:ss'):moment().format('DD.MM.YYYY hh:mm:ss')}}</span>
        
     </div>

       <div class="mb-7" style="overflow-x:auto;">
        <div class="d-flex">
          <div class="col-6 py-2 text-center top-1-items">
            {{ $t('order.supplier_company') }}
          </div>
          <div class="col-6 py-2 text-center top-1-items">
            {{ $t('order.dispatch_location') }}
          </div>
          <div class="col-6 py-2 text-center top-1-items">
            {{ $t('order.knitting_company') }}
          </div>
          <div class="col-6 py-2 text-center top-1-items">
            {{ $t('order.processes') }}
          </div>
        </div>
        <div class="d-flex">
          <div class="col-6 d-flex justify-content-center items-center py-2 text-center top-1-items">
            <custom-multi-select
                :has-bottom-margin="false"
                :item-per-row="1"
                :max="1"
                :model.sync="currentDetail.supplier_company_id"
                :disabled="currentDetail.lockable_company &&  isUserGranted('Order', ['updateInstructionSupplierCompany'],false,true)"
                :name="`order_fabric_paint_type_supplier_company_${index}`"
                :not-list="true"
                :options="supplierCompanyOptions"
                :required="false"
                :title="null"
                size="sm"
            ></custom-multi-select>
          </div>
          <div class="col-6 d-flex justify-content-center items-center py-2 text-center top-1-items">
            <custom-multi-select
                :has-bottom-margin="false"
                :is-right="true"
                :item-per-row="1"
                :model.sync="currentDetail.dispatch_location_ids"
                :name="`order_fabric_paint_type_fabric_process_${index}`"
                :options="dispatchLocationOptions"
                :required="false"
                :title="null"
                :max="1"
                size="sm"
            ></custom-multi-select>
          </div>

          <div class="col-6 d-flex justify-content-center items-center py-2 text-center top-1-items">
            <custom-multi-select
                :has-bottom-margin="false"
                :item-per-row="1"
                :max="1"
                :model.sync="currentDetail.knitter_company_id"
                :name="`order_fabric_paint_type_knitting_company_${index}`"
                :not-list="true"
                :options="supplierCompanyOptions"
                :required="false"
                :title="null"
                size="sm"
            ></custom-multi-select>
          </div>
          <div class="col-6 d-flex justify-content-center items-center py-2 text-center top-1-items">
            <custom-multi-select
                :has-bottom-margin="false"
                :item-per-row="1"
                :max-show="1"
                :model.sync="currentDetail.fabric_process_ids"
                :name="`order_fabric_paint_type_fabric_process_${index}`"
                :options="fabricProcessOptions"
                :required="false"
                :title="null"
                size="sm"
            ></custom-multi-select>
          </div>
        </div>
       </div>

        <date-detail-table-row
            :model="currentDetail"
        ></date-detail-table-row>

        <div class="mb-7" style="overflow-x:auto;">
          <div class="d-flex">
          <div class="col-6 py-2 text-center top-1-items">
            {{ $t('order.color') }}
          </div>
          <div class="col-6 py-2 text-center top-1-items">
            {{ $t('order.fabric_type') }}
          </div>
          <div class="col-6 py-2 text-center top-1-items">
            {{ $t('order.color') }}
          </div>
          <div class="col-6 py-2 text-center top-1-items">
            {{ $t('order.color_ok') }}
          </div>
          <div class="col-6 py-2 text-center top-1-items">
            {{ $t('order.weight_with_unit') }}
          </div>
          <div class="col-6 py-2 text-center top-1-items">
            {{ $t('order.width') }}
          </div>
        </div>
        <div class="d-flex">
          <div class="col-6 py-2 text-center top-1-items">
            <div class="px-2">
              <custom-multi-select
                  :item-per-row="1"
                  :max="1"
                  :model.sync="currentDetail.order_size_and_color_id"
                  :not-list="true"
                  :options="orderSizeAndColorOptions"
                  :placeholder="$t('general.please_select_option')"
                  :required="false"
                  :title="null"
                  name="orderSizeAndColorOptions"
                  width="300px"
                  @onSelect="orderSizeAndColorSave($event, index)"
              ></custom-multi-select>
            </div>
          </div>
          <div class="col-6 py-2 text-center top-1-items">
            <div class="px-2">
              <input
                  v-model="currentDetail.fabric_type"
                  :class="{'is-invalid': !currentDetail.fabric_type}"
                  :name="`order_fabric_paint_type_fabric_type_${index}`"
                  :placeholder="$t('order.please_enter_fabric_type')"
                  class="form-control form-control-sm"
                  type="text"
              >
            </div>
          </div>
          <div class="col-6 py-2 text-center top-1-items">
            <div class="px-2">
              <input
                  :disabled="true"
                  v-model="currentDetail.color"
                  :class="{'is-invalid': !currentDetail.color}"
                  :name="`order_fabric_paint_type_color_${index}`"
                  :placeholder="$t('order.please_enter_color')"
                  class="form-control form-control-sm"
                  type="text"
              >
            </div>
          </div>
          <div class="col-6 py-2 text-center top-1-items">
            <div class="px-2">
              <input
                  v-model="currentDetail.color_ok"
                  :class="{'is-invalid': !currentDetail.color_ok}"
                  :name="`order_fabric_paint_type_color_arrow_${index}`"
                  :placeholder="$t('order.please_enter_color_ok')"
                  class="form-control form-control-sm"
                  type="text"
              >
            </div>
          </div>
          <div class="col-6 py-2 text-center top-1-items">
            <div class="px-2">
              <input
                  v-model="currentDetail.weight"
                  :class="{'is-invalid': !currentDetail.weight}"
                  :name="`order_fabric_paint_type_weight_${index}`"
                  :placeholder="$t('order.please_enter_weight')"
                  class="form-control form-control-sm"
                  type="text"
              >
            </div>
          </div>
          <div class="col-6 py-2 text-center top-1-items">
            <div class="px-2">
              <input
                  v-model="currentDetail.width"
                  :class="{'is-invalid': !+currentDetail.width}"
                  :name="`order_fabric_paint_type_width_${index}`"
                  :placeholder="$t('order.please_enter_width')"
                  class="form-control form-control-sm"
                  type="text"
              >
            </div>
          </div>
        </div>
        </div>
        

        <div class="mb-7" style="overflow-x: auto;">
          <div class="d-flex">
          <div class="col-6 py-2 text-center top-1-items">
            {{ $t('order.quantity') }}
          </div>
          <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
            {{ $t('order.unit') }}
          </div>
          <div class="col-6 py-2 text-center top-1-items">
            {{ $t('order.tolerance_quantity') }}(%)
          </div>
          <div class="col-6 py-2 text-center top-1-items">
            {{ $t('order.total_quantity') }}
          </div>
          <div class="col-6 py-2 text-center top-1-items">
            {{ $t('order.incoming_quantity') }}
          </div>
          <div class="col-6 py-2 text-center top-1-items">
            {{ $t('order.remaining_quantity') }}
          </div>
        </div>
        <div class="d-flex">
          <div class="col-6 py-2 text-center top-1-items">
            <div class="p-2">
              <input
                  v-model="currentDetail.amount"
                  :class="{'is-invalid': !currentDetail.amount}"
                  :name="`order_fabric_knit_type_amount_${index}`"
                  :placeholder="$t('order.please_enter_quantity')"
                  :required="false"
                  :step="1"
                  :title="null"
                  class="form-control form-control-sm"
                  min="0"
                  type="number"
              />
            </div>
          </div>
          <div
              class="flex-1 py-2 d-flex flex-column items-center justify-content-center text-center navy-blue-border-right-1px">
              <div class="p-2">
              <custom-multi-select
                  :has-bottom-margin="false"
                  :item-per-row="2"
                  :max="1"
                  :model.sync="currentDetail.amount_unit_id"
                  :name="`amount_unit_id_${index}`"
                  :not-list="true"
                  :options="amountUnitOptions"
                  :required="false"
                  :title="null"
                  size="sm"
              ></custom-multi-select>
            </div>
          </div>
          <div class="col-6 py-2 text-center top-1-items">
            <div class="px-2">
              <input
                  v-model="currentDetail.tolerance_amount"
                  :class="{'is-invalid': !+currentDetail.tolerance_amount || +currentDetail.tolerance_amount > 100 || +currentDetail.tolerance_amount < 0 }"
                  :disabled="true"
                  :name="`order_fabric_knit_type_amount_tolerance_quantity_${index}`"
                  :placeholder="$t('order.please_enter_tolerance_amount')"
                  :required="false"
                  :step="1"
                  :title="null"
                  class="form-control form-control-sm"
                  type="number"
              >
            </div>
          </div>
          <div class="col-6 py-2 text-center top-1-items d-flex justify-content-center items-center">
            {{ totalAmount(index) | amountFormat}}
          </div>
          <div class="col-6 py-2 text-center top-1-items d-flex justify-content-center items-center">{{ currentDetail.incoming_amount | amountFormat }}</div>
          <div class="col-6 py-2 text-center d-flex justify-content-center items-center top-1-items"> {{ currentDetail.remaining_amount | amountFormat  }}</div>
        </div>
        
        </div>
       

        <test-method-row
            :product-processes.sync="modelProxy[index].product_processes"
            :selected-test-methods.sync="modelProxy[index].selectedProductProcesses"
            :test-method-options="productProcesses"
        ></test-method-row>

        <currency-table-row
            :currency-options="currencyOptions"
            :currency-options-raw="currencyOptionsRaw"
            :currency-rate-options="currencyRateOptions"
            :expiration-type-options="expirationTypeOptions"
            :model="currentDetail"
            :v-a-t-rate-options="VATRateOptions"
        ></currency-table-row>

        <div class="d-flex">
          <div class="flex-1 px-2 py-3">
            <textarea-input
                :is-valid="!!currentDetail.description"
                :model.sync="currentDetail.description"
                :name="`order_fabric_yarn_fabric_description_${index}`"
                :required="false"
                :title="$t('order.description')"
            ></textarea-input>
          </div>
        </div>
      </div>
      <span v-if="index > 0 && !currentDetail.id" class="cursor-pointer" @click="deleteNewDetail(index)" v-html="getIconByKey('icons.sample.delete_red',{
                        class: 'w-20px h-20px d-inline-block mr-3 '
                      })"></span>

      <span v-if="isValidToSubmit && index == modelProxy.length - 1"
            class="cursor-pointer"
            @click="addNewDetail"
            v-html="getIconByKey('icons.waybill.waybill_entry', {
            class: 'w-50px h50px d-line-block object-fill',
          })"
      ></span>

      <bottom-buttons
          :emails="[]"
          :is-editing.sync="currentDetail.is_editing"
          :is-valid-to-submit="isValidToSubmit(index)"
          :model="currentDetail"
          :order-id="orderId"
          :send-mail-url="sendMailUrl(index)"
          name="paint-request"
          @addNewDetail="addNewDetail"
          @onCancelInstruction="onCancelInstruction($event, currentDetail.id, index)"
          @submit="submitOrderFabricPaint ($event, index)"
      ></bottom-buttons>
    </template>
  </div>
</template>

<script>
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import DatePickerInput from "@/assets/components/inputs/DatePickerInput";
import TextareaInput from "@/assets/components/inputs/TextareaInput";
import CurrencyTableRow from "@/view/pages/order/layout/CurrencyTableRow";
import TestMethodRow from "@/view/pages/order/layout/TestMethodRow";
import _ from "lodash";
import DateDetailTableRow from "@/view/pages/order/layout/DateDetailTableRow";
import BottomButtons from "@/view/pages/order/layout/BottomButtons";
import moment from "moment";
import {LARAVEL_DATE_TIME_FORMAT} from "@/core/config/constant";
import {CREATE_ITEM, DELETE_ITEM_BY_ID} from "@/core/services/store/REST.module";
import FollowingExportTasks from "@/view/pages/following-export/tasks/FollowingExportTasks";

export default {
  name: "OrderFabricPaintTypeTable",
  components: {
    BottomButtons,
    DateDetailTableRow, TestMethodRow, CurrencyTableRow, TextareaInput, DatePickerInput, CustomMultiSelect
  },
  props: {
    model: {
      required: true,
    },
    totalInstructions:{
      required:false,
    },
    maxPrice:{
      required: false,
    },
    maxPriceUnit:{
      required:false,
    },

    previousModel: {
      required: true,
    },
    yarnModel: {
      required: true,
      type: Array,
    },
    amountUnitOptions: {
      required: true
    },
    defaultModel: {
      required: true,
    },
    orderId: {
      required: true,
      default: null,
    },
    order: {
      required: true,
      default: null
    },
    currentInstructionTypeId: {
      required: true,
    },
    supplierCompanyOptions: {
      required: true,
    },
    fabricProcessOptions: {
      required: true,
    },
    dispatchLocationOptions: {
      required: true,
    },
    currencyOptions: {
      required: true,
    },
    currencyOptionsRaw: {
      required: true
    },
    expirationTypeOptions: {
      required: true,
    },
    VATRateOptions: {
      required: true,
    },
    currencyRateOptions: {
      required: true,
    },
    didChangeProcessingType: {
      required: true,
      type: Boolean,
    },
    tolerances: {
      required: true,
    },
    productProcesses: {
      required: true,
    },
    orderFabricOptions: {
      required: true,
    },
    fabricProcessingType: {
      required: true,
    },
    orderFabricId: {
      required: true,
    },
    orderSizeAndColorOptions: {
      required: true,
    },
    orderSizeAndColorNameOptions: {
      required: true,
    }
  },
  data() {
    return {
      is_submitting: false
    }
  },
  methods: {
    maxPriceCheck(index){
      console.log(index);
      let modelTotal = 0;
      let fabricCurrencyUnit=this.maxPriceUnit;
      let allCurrencyRate=this.currencyRateOptions;
      let orderFabric = _.find(this.order.order_fabrics,{'id':+this.orderFabricId});
      let modelItem = this.model[index];

      let modelExchanegeRate=1;
      let modelCurrencyUnit=null;
      if(modelItem.currency_unit){
        modelCurrencyUnit=modelItem.currency_unit.code;
      }else{
        modelCurrencyUnit=this.currencyOptions[modelItem.currency_unit_id];
      }
      
      let findCurrencyForFabric=_.find(allCurrencyRate,{"code":fabricCurrencyUnit});
      let findCurrencyForModel=_.find(allCurrencyRate,{"code":modelCurrencyUnit});

      if((modelCurrencyUnit=="TL"||modelCurrencyUnit=="TRY")&&(fabricCurrencyUnit=="TRY"||fabricCurrencyUnit=="TL")){
        modelExchanegeRate=findCurrencyForFabric.rate;
        modelTotal+=Number(modelItem.total_price)/modelExchanegeRate;
      }else if(fabricCurrencyUnit==modelCurrencyUnit){
        modelTotal+=Number(modelItem.total_price);
      }else{

        modelExchanegeRate=findCurrencyForModel.rate/orderFabric.exchange_rate;
        modelTotal+=Number(modelItem.total_price)*modelExchanegeRate;
      }
      let modelPlusTotalAllInstructions=this.totalInstructions;
      console.log(modelPlusTotalAllInstructions);
      if(modelPlusTotalAllInstructions>this.maxPrice){
        return false;
      }
      return true;
    },

    orderSizeAndColorSave(selectedValue, index) {
      let model = this.modelProxy[index];
      let value = Number(selectedValue);
      let amount = 0;
      if (+model.fabric_supply_type_id === 1) {
        let yarnModels = this.yarnModel.filter(item => +item.order_size_and_color_id === +value);
        yarnModels.forEach(yarnModel => {
          amount += Number(yarnModel.amount);
        })
      } else if (+model.fabric_supply_type_id === 3) {
        let yarnModels = this.previousModel.filter(item => +item.order_size_and_color_id === +value);
        yarnModels.forEach(yarnModel => {
          amount += Number(yarnModel.amount);
        })
      }

      model.amount = amount ? amount.toFixed(2) : 0;

      model.color = this.orderSizeAndColorNameOptions[value];
    },
    changedOrderFabric(index) {
      let newId = this.modelProxy[index].order_fabric_id;
      let newItem = this.orderFabricOptions.filter(x => parseInt(x.id) === parseInt(newId))[0];
      this.modelProxy[index].weight = newItem.weight;
      this.modelProxy[index].width = newItem.width;
    },
    onCancelInstruction($event, itemId, index) {
      const self = this;
      this.$store.dispatch(DELETE_ITEM_BY_ID, {
        url: `api/order/${this.orderId}/paint-request/${itemId}`
      }).then(function (response) {
        if (response.status) {
          self.sweetAlertSuccess(self.$t('general.successfully_deleted'));
          self.modelProxy.splice(index, 1);
          if (!self.modelProxy.length) {
            self.modelProxy = [self.defaultModel];
          }
        } else {
          self.sweetAlertError(self.$t('general.internal_error'));
        }
      });
    },
    deleteNewDetail(index) {
      this.modelProxy.splice(index, 1);
    },
    addNewDetail() {
      let copy = _.cloneDeepWith(this.defaultModel);
      copy.is_submitted = false;
      copy.is_editing = false;
      copy.amount = 0;
      copy.id = null;

      let orderFabricId = this.orderFabricId;
      let fabricItem = this.orderFabricOptions.find(x => parseInt(x.id) === parseInt(orderFabricId));

      copy.width = fabricItem.width;
      copy.weight = fabricItem.weight;

      this.modelProxy.push(copy);
    },
    fixedData(index) {
      let items = [];
      let self = this;
      let item = this.modelProxy[index];
      item.dispatch_locations = item.dispatch_location_ids.map(location => {
        return {
          id: location,
        }
      });
      item.fabric_processes = item.fabric_process_ids.map(process => {
        return {
          id: process,
        };
      });

      item.order_fabric_id = self.orderFabricId;
      item.raw_flow_date = moment(item.raw_flow_date).format(LARAVEL_DATE_TIME_FORMAT);
      item.raw_end_date = moment(item.raw_end_date).format(LARAVEL_DATE_TIME_FORMAT);
      item.painted_flow_date = moment(item.painted_flow_date).format(LARAVEL_DATE_TIME_FORMAT);
      item.painted_end_date = moment(item.painted_end_date).format(LARAVEL_DATE_TIME_FORMAT);

      items.push(item);

      return items;
    },
    submitOrderFabricPaint($event, index) {
      let maxControl = this.maxPriceCheck(index);
      if(maxControl==false){
        this.sweetAlertError(this.$t('order.instruction_max_error'));
        return false;
      }

      if (this.is_submitting) return false;
      this.is_submitting = true;
      this.sweetAlertLoading();
      this.updateOrCreate(index);
    },
    updateOrCreate(index) {
      const self = this;
      self.$store.dispatch(CREATE_ITEM, {
        url: `api/order/${self.orderId}/paint-request`,
        contents: {
          order_paint_requests: self.fixedData(index),
        }
      }).then(response => {
        if (response.status) {
          let detail = self.modelProxy[index];
          let is_editing = !!detail.id;
          let responseData = response.data[0];
          detail.id = responseData.id
          detail.is_submitted = true;
          detail.is_editing = false;
          detail.pdf_link = responseData.pdf_link;
          detail.order_fabric_excel_link = responseData.order_fabric_excel_link
          detail.remaining_amount = responseData.remaining_amount

          self.modelProxy[index] = detail;
          self.sweetAlertSuccess(self.$t(is_editing ? 'general.successfully_updated' : 'general.successfully_created'));
        } else {
          let result = response.data.response;
          if (result.hasOwnProperty('data') && result.data.hasOwnProperty('message')) {
            self.sweetAlertError(result.data.message);
          } else {
            self.sweetAlertError(result.data);
          }
        }
        self.sweetAlertClose();
        self.is_submitting = false;
      });
    }
  },
  computed: {
    moment: () => moment,
    getOrderFabricOptions() {
      let items = this.orderFabricOptions;
      return this.convertArrayToObjectByKey(items, 'id', 'type');
    },
    modelProxy: {
      get() {
        return this.model;
      },
      set(value) {
        this.$emit('update:model', value);
      }
    },
    totalAmount() {
      return function (index) {
        this.modelProxy[index].total_amount = this.modelProxy[index].amount * (1 + (+this.modelProxy[index].tolerance_amount / 100))
        return (+this.modelProxy[index].total_amount);
      }
    },
    isValidToSubmit() {
      try {
        let returnData = null;

        returnData = function (key) {
          const current = this.modelProxy[key];
          if (!+current.supplier_company_id) return false;
          if (!+current.knitter_company_id) return false;
          if (!current.fabric_process_ids.length) return false;
          if (!current.dispatch_location_ids.length) return false;
          if (current.fabric_type === null || current.fabric_type === '') return false;
          if (current.color === null || current.color === '') return false;
          if (current.color_ok === null || current.color_ok === '') return false;
          if (current.weight === null || current.weight === '') return false;
          if (!+current.width) return false;
          if (!+current.amount) return false;
          if (current.selectedProductProcesses.length === 0) return false;
          if (!+current.currency_unit_id) return false;
          if (!+current.order_size_and_color_id) return false;
          if (!+current.expiry_id) return false;
          if (!+current.unit_price) return false;
          if (!+current.vat_rate_id) return false;
          if (current.description === null || current.description === '') return false;
          return true;
        }

        return returnData;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    sendMailUrl() {
      try {
        let returnData = null;
        returnData = function (key) {
          const itemId = this.modelProxy[key].id;
          return `api/order/${this.orderId}/paint-request/${itemId}/share`
        }
        return returnData;
      } catch (e) {
        console.error(e);
        return false;
      }
    }
  },
  mounted() {
    if (this.previousModel && this.previousModel.length > 0) {
      this.modelProxy.forEach(model => {
        let orderFabricId = this.orderFabricId;

        model.fabric_supply_type_id = this.fabricProcessingType;
        model.tolerance_amount = this.maxTolerance;
        model.order_fabric_id = orderFabricId

        let fabricItem = this.orderFabricOptions.find(x => parseInt(x.id) === parseInt(orderFabricId));

        if (!model.width) {
          model.width = fabricItem.width;
        }
        if (!model.weight) {
          model.weight = fabricItem.weight;
        }

        if (!+model.supplier_company_id) {
          model.supplier_company_id = this.previousModel[0].supplier_company_id;
        }
        if (!+model.knitter_company_id) {
          model.knitter_company_id = this.previousModel[0].supplier_company_id;
        }
      })
    }
    if(this.$route.query.qr_type=="order-paint"){
        setTimeout(() => {
            let instructionsId = this.$route.query.data.id
            let element = this.$refs["paint_request_"+instructionsId][0]
            if(element){
              element.scrollIntoView({behavior: "smooth"})
            }
          }, 2000);
      }
  },
  watch: {
    modelProxy: {
      handler(newValue, oldValue) {
        if (newValue) {
          let totalAmount = _.sumBy(this.modelProxy, 'amount');
          newValue.forEach(model => {
            model.fabric_supply_type_id = this.fabricProcessingType;
            model.tolerance_amount = this.tolerances.paint_coefficient;
            model.order_fabric_id = this.orderFabricId;
            if (!model.amount) {
              model.amount = _.sumBy(this.previousModel, 'amount') - totalAmount;
            }
          })
        }
      },
      deep: true
    },
    orderFabricId(value) {
      this.modelProxy.forEach(model => {
        model.order_fabric_id = value;
        if (!model.amount) {
          model.amount = _.sumBy(this.previousModel, 'amount');
        }
      })
    },
    tolerances(value) {
      if (value) {
        this.modelProxy.forEach(model => {
          model.tolerance_amount = this.tolerances ? this.tolerances.paint_coefficient : 1;
        })
      }
    }
  }
}
</script>
