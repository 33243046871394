<template>
  <div class="row">
    <div class="col-md-12">
      <dashboard-box>
        <template v-slot:preview>
          <div class="px-3 main_wrapper">
            <h4 class="use-font text-center">Numuneden Sipariş Oluştur</h4>
            <div class="card_top">
                <div class="img-div">
                    <img :src="getIconByKey('general.no-image')" alt="NO-IMAGE">
                    <div class="w-20px h-20px ml-8 plus_icon">
                    <span 
                    v-html="getIconByKey('icons.waybill.waybill_entry', 
                    {class:'w-30px h-30px d-inline-block object-fill cursor-pointer',})">
                    </span>
                  </div>
                </div>
                <div class="qr-div">
                    <h4 style="color: rgba(10,10,10,0.4); font-weight:700;">QR KOD</h4>
                </div>
            </div>
            <div class="card_center">
                <div class="center_left">
                    <div class="center_left_items"><span class="menu_label">Koleksiyon Numune:</span><span class="text-left" style="width: 50px;">Seçiniz</span></div>
                    <div class="center_left_items"><span class="menu_label">ESC No:*</span><span class="text-left" style="width: 50px;">Seçiniz</span></div>
                    <div class="center_left_items"><span class="menu_label">Müşteri:*</span><span class="text-left" style="width: 50px;">Seçiniz</span></div>
                    <div class="center_left_items"><span class="menu_label">Model No:*</span><span class="text-left" style="width: 50px;">Giriniz</span></div>
                </div>
                <div class="center_right">
                    <div class="center_right_items"><span class="menu_label">Durum:*</span><span class="text-left" style="width: 50px;">Seçiniz</span></div>
                    <div class="center_right_items"><span class="menu_label">Toplam:*</span><span class="text-left" style="width: 50px;">Giriniz</span></div>
                    <div class="center_right_items"><span class="menu_label">Sipariş No:</span><span class="text-left" style="width: 50px;">S00078</span></div>
                    <div class="center_right_items"><span class="menu_label">PO No:</span><span class="text-left" style="width: 50px;">Giriniz</span></div>
                </div>
            </div>
            <div class="order_menu_x">
                <div :class="{'order_menu_item_x_active':currentActiveTab === 4,}" class="order_menu_item_x"
                  @click="currentActiveTab = 4">
                  {{ $t("csr.self").toUpperCase() }}
                </div>

                <div :class="{'order_menu_item_x_active':currentActiveTab === 1,}" class="order_menu_item_x" 
                @click="currentActiveTab = 1">
                  {{ $t("order.general").toUpperCase() }}
                </div>

                <div :class="{'order_menu_item_x_active':currentActiveTab === 2,}" class="order_menu_item_x"
                  @click="form.is_submitted ? (currentActiveTab = 2) : ''">
                  {{ $t("order.suppliers_and_manufacturer").toUpperCase() }}
                </div>

                <div :class="{'order_menu_item_x_active':currentActiveTab === 3,}" class="order_menu_item_x"
                  @click="form.is_submitted ? (currentActiveTab = 3) : ''">
                  {{ $t("order.report").toUpperCase() }}
                </div>

            </div>
            <div class="bottom-btns col-12 text-center">
                <div class="col flex-grow-1 p-3">
                  <div class="flex-grow-1 justify-content-center row">
                    <div class="btn-w-icn d-flex justify-content-center mt-3">
                      
                      <button-with-icon
                        :disabled="
                          !(
                            isUserGranted(
                              'Order',
                              ['create', 'update'],
                              false
                            ) ||
                            isUserGranted('Csr', ['create', 'update'], false)
                          )
                        "
                        :size="'lg'"
                        :text="$t('general.save').toUpperCase()"
                        icon-name="icons.waybill.approve"
                        @onClick="submitOrderCsr"
                        class="detail-bottom-btn"
                      ></button-with-icon>
                      <span
                        class="px-3 green-border-1px rounded-full d-flex justify-content-center items-center w-125px mx-2 cursor-pointer detail-bottom-btn"
                        @click="$router.push({ name: 'order.index' })"
                      >
                        <span
                          v-html="
                            getIconByKey('icons.waybill.give_up', {
                              class:
                                'detail-close-btn mr-2 w-40px h-40px object-cover d-inline-block opacity-75',
                            })
                          "
                        >
                        </span>
                        {{ $t("general.give_up").toUpperCase() }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>
<script>
import DashboardBox from "@/assets/components/DashboardBox";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapMutations, mapState } from "vuex";
import {
  CREATE_ITEM,
  DELETE_ITEM_BY_ID,
  GET_ITEM_DETAIL_BY_ID,
  GET_ITEMS,
  LOADING,
  SET_LOADING,
  UPDATE_ITEM_BY_ID,
} from "@/core/services/store/REST.module";
import NumberInput from "@/assets/components/inputs/NumberInput";
import {
  EXPENSE_DETAIL,
  FORM,
  RESET_FORM,
  SET_EXPENSE_DETAIL,
  SET_FORM,
} from "@/core/services/store/order/edit-order.module";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import TextInput from "@/assets/components/inputs/TextInput";
import _ from "lodash";
import csrData from "@/view/pages/csr/csr-data.js";

import moment from "moment";
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";
import DatePickerInput from "@/assets/components/inputs/DatePickerInput";
import NumberInputWithUnit from "@/assets/components/inputs/NumberInputWithUnit";
import {
  LARAVEL_DATE_FORMAT,
  LARAVEL_DATE_TIME_FORMAT,
} from "@/core/config/constant";
import QRCode from "@/assets/components/widget/QRCode";
import Tooltip from "@/view/pages/vue-bootstrap/Tooltip";
import InfoTooltip from "@/assets/components/widget/InfoTooltip";
import OrderFabric from "@/view/pages/order/layout/OrderFabric";
import InstructionsModal from "@/view/pages/shared/instructionsModal";
import moduleUser, {
  GENERAL_SETTING,
  MODULE_NAME as MODULE_USER,
} from "@/core/services/store/user.module";
import store from "@/core/services/store";
import $ from "jquery";
import pdf from "vue-pdf";

const _MODULE_USER = MODULE_USER;

export default {
  name: "CreateNewOrder",
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }

    registerStoreModule(_MODULE_USER, moduleUser);
  },
  components: {
    InstructionsModal,
    OrderFabric,
    InfoTooltip,
    Tooltip,
    QRCode,
    NumberInputWithUnit,
    DatePickerInput,
    ButtonWithIcon,
    TextInput,
    CustomMultiSelect,
    NumberInput,
    DashboardBox,
    pdf,
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
    }),
    isDisablePriceEdit() {
      return this.isUserGranted("Order", ["updatePrice"], false, true)
        ? false
        : true;
    },
    defaultAmount() {
      return function (data, value, key) {
        if (key === 2) {
          let item = this.defaultTotals.find((item) => item.id === data.id);
          return item
            ? item.body_sizes.find(
                (item) => item.body_size_id === value.body_size_id
              ).amount
            : 0;
        }
      };
    },
    sizeAndColorOptions() {
      let self = this;
      let items = this.form.order_size_and_colors;

      if (items) {
        return self.convertArrayToObjectByKey(items, "id", "color_name");
      }

      return {};
    },
    vatRateOptions() {
      let options = this.options.VATRateOptionsRaw || {};
      let data = options;

      let activeTab = this.activeTab;
      if (+activeTab === 1) {
        let expenseTypeId = this.expense_data.typeId;
        if (options && expenseTypeId) {
          data = data.filter((item) => +item.type_id === expenseTypeId);
        }
      } else if (+activeTab === 2) {
        data = options.filter((item) => +item.type_id === 4);
      }
      return this.convertArrayToObjectByKey(data, "id", "vat.rate");
    },
    ...mapState({
      form: (state) => state.editOrder[FORM],
      expense_details: (state) => state.editOrder[EXPENSE_DETAIL],
    }),
    totalSizeAndColor() {
      return function (item) {
        let items = _.values(item.body_sizes);
        let amount = 0;
        items.forEach((subItem) => {
          amount += Number(subItem.amount);
        });

        return amount;
      };
    },
    expenseCoefficient() {
      let fabricRequests = 10;

      if (this.settings) {
        let settings = _.first(this.settings);
        if (settings) {
          fabricRequests = settings.fabric_request.expense_coefficient;
        }
      }
      return fabricRequests;
    },
    settings() {
      return this.$store.getters[_MODULE_USER + "/" + GENERAL_SETTING];
    },
    yesNoOptions() {
      let data = csrData.yesNo;
      return this.convertArrayToObjectByKey(data, "id", "name");
    },
    disneyLicences() {
      let data = csrData.disneyLicences;
      return this.convertArrayToObjectByKey(data, "id", "name");
    },
    joinLifeOptions() {
      let data = csrData.joinLife;
      return this.convertArrayToObjectByKey(data, "id", "name");
    },
    finalTotal() {
      let total = 0;
      this.form.order_fabrics.forEach((fabric) => {
        total += Number(fabric.pastal);
      });

      return total * Number(this.form.amount);
    },

    currentOrderExpenseDetail: {
      get() {
        if (this.expense_data.selectedExpenseID === null) return null;
        if (this.expense_data.selectedExpenseID in this.expense_details) {
          return this.expense_details[this.expense_data.selectedExpenseID];
        }
        return null;
      },
      set(value) {
        if (this.expense_data.selectedExpenseID === null) return null;
        if (!(this.expense_data.selectedExpenseID in this.expense_details)) {
          return null;
        }
        this.$set(this.expense_details, this.expense_data.selectedExpenseID, {
          ...value,
        });
      },
    },
    fabricTotalAmount() {
      let total = 0;
      for (let i = 0; i < this.form.order_fabrics.length; i++) {
        total +=
          Number(this.form.order_fabrics[i].pastal) *
          Number(this.form.order_fabrics[i].unit_price);
      }
      let rate = 1;
      /*      if (this.form.currency_unit_id in this.options.currencyOptions) {
              rate = Number(this.options.currencyRateOptions[this.options.currencyOptions[this.form.currency_unit_id]].rate);
            }*/
      return (isNaN(total * rate) ? 0 : total * rate).toFixed(2);
    },
    expenseTotalAmount() {
      let total = 0;
      for (let i = 0; i < this.form.order_expenses.length; i++) {
        total +=
          Number(this.form.order_expenses[i].amount) *
          Number(this.form.order_expenses[i].planned);
      }
      let rate = 1;
      /*      if (this.form.currency_unit_id in this.options.currencyOptions) {
              rate = Number(this.options.currencyRateOptions[this.options.currencyOptions[this.form.currency_unit_id]].rate);
            }*/
      return (isNaN(total * rate) ? 0 : total * rate).toFixed(2);
    },
    sizeAndColorTotal() {
      let total = 0;
      this.form.order_size_and_colors.forEach((value, index) => {
        for (let key in value.body_sizes) {
          total += Number(value.body_sizes[key].amount);
        }
      });
      return total;
    },
    sizeAndColorTotalByIndex() {
      let size_and_colors = this.form.order_size_and_colors;
      return function (index) {
        let current = size_and_colors[index],
          total = 0;
        for (let key in current.body_sizes) {
          total += Number(current.body_sizes[key].amount);
        }
        return total;
      };
    },
    isOrderExportDateAmountValid() {
      if (Number(this.form.amount) === 0) return false;
      let total = 0;
      for (let i = 0; i < this.form.order_export_dates.length; i++) {
        total += Number(this.form.order_export_dates[i].amount);
      }
      if (total !== Number(this.form.amount)) return false;
      return true;
    },
    isValidToContinueOnGeneral() {
      if (this.form.esc_table_id === null) return false;
      if (this.form.model === null || this.form.model === "") return false;
      if (Number(this.form.company_id) === 0) return false;
      if (Number(this.form.status_id) === 0) return false;
      if (this.form.po_number === null || this.form.po_number === "")
        return false;
      if (this.form.image_link === null && this.form.image) return false;
      if (Number(this.form.amount) === 0) return false;

      return true;
    },
    isValidToContinueOnFabric() {
      if (!this.isValidToContinueOnGeneral) return false;
      if (Number(this.form.sale_price) === 0) return false;
      if (Number(this.form.currency_unit_id) === 0) return false;
      let total = 0;
      for (let i = 0; i < this.form.order_export_dates.length; i++) {
        if (this.form.order_export_dates[i].export_date === null) return false;
        if (Number(this.form.order_export_dates[i].amount) === 0) return false;
        total += Number(this.form.order_export_dates[i].amount);
      }
      if (total !== Number(this.form.amount)) return false;
      if (this.form.description === null || this.form.description === "")
        return false;
      if (this.form.amount_per_parcel === null) return false;
      return true;
    },
    isValidToContinueOnExpenses() {
      if (!this.isValidToContinueOnFabric) return false;
      for (let i = 0; i < this.form.order_fabrics.length; i++) {
        let current = this.form.order_fabrics[i];
        if (current.type === null || current.type === "") return false;
        if (!current.weight) return false;
        if (!current.width) return false;
        if (Number(current.pastal) === 0) return false;
        if (Number(current.unit_id) === 0) return false;
        if (Number(current.unit_price) === 0) return false;
        let total = 0;
        if (current.order_fabric_compositions.length === 0) return false;
        for (let j = 0; j < current.order_fabric_compositions.length; j++) {
          let temp = current.order_fabric_compositions[j];
          if (Number(temp.fabric_composition_id) === 0) return false;
          if (Number(temp.amount) === 0) return false;
          total += Number(temp.amount);
        }
        if (total !== 100) return false;
      }
      return true;
    },
    isValidToContinueOnSizeAndColor() {
      if (!this.isValidToContinueOnExpenses) return false;
      for (let i = 0; i < this.form.order_expenses.length; i++) {
        let current = this.form.order_expenses[i];
        if (Number(current.planned) === 0) return false;
        if (Number(current.amount) === 0) return false;
        if (Number(current.unit_id) === 0) return false;
        if (Number(current.currency_unit_id) === 0) return false;
      }
      return true;
    },
    isValidToSubmit() {
      if (!this.isValidToContinueOnSizeAndColor) return false;
      let total = 0;

      for (let i = 0; i < this.form.order_size_and_colors.length; i++) {
        const size = this.form.order_size_and_colors[i],
          tempTotal = Number(this.sizeAndColorTotalByIndex(i));
        if (tempTotal === 0) return false;
        total += tempTotal;
        if (size.color_number === null || size.color_number === "")
          return false;
        if (size.color_name === null || size.color_name === "") return false;
      }
      if (Number(total) !== Number(this.form.amount)) return false;
      return true;
    },
  },
  data() {
    return {
      pdfs: [],

      src: null,
      numPages: undefined,
      currentPDF: {
        blob: null,
      },

      imageLinks: [],
      imageIndex: null,

      csr: {
        disney_licences: {
          disney_id: null,
          license_id: null,
        },
        join_life: [],
      },
      instructionTypes: null,
      instructions: null,
      expenses: [],
      orderAllResult: [],
      is_submitting: false,
      defaultTotals: [],
      expense_data: {
        orderExpenseId: null,
        selectedExpenseID: null,
        typeId: null,
        instructionTypeId: null,
      },

      today: moment(),
      orderDate: moment(),

      // Control Variables
      currentActiveTab: 4,
      defaultSizeJSON: {},

      id: this.$route.params.id,
      type: Number(this.$route.params.type),
      esc_id: Number(this.$route.query.esc_id),
      sample_id: Number(this.$route.query.sample_id),
      activeTab: 1,

      instructionTypeId: null,
      csrSupplierCompanies: {},
      options: {
        customerOptions: [],
        orderStatusOptions: [],
        currencyOptions: {},
        currencyRawOptions: [],
        qcUserOptions: [],
        fabricCompositionOptions: [],
        expensesOptions: [],
        optionalExpenseOptions: [],
        unitOptions: [],
        unitOptions2: [],
        unitOptions3: [],
        bodySizeOptions: [],
        supplierCompanyOptions: [],
        dispatchLocationOptions: [],
        snapTypeOptions: [],
        VATRateOptions: [],
        VATRateOptionsRaw: [],
        currencyRateOptions: [],
        fabricYarnTypeOptions: [],
        testMethodOptions: [],
        fabricKnitTypeOptions: [],
        fabricTypeOptions: [],
        fabricProcessTypeOptions: [],
        fabricAdditionOptions: [],
        fabricPrintTypeOptions: [],
        productProcesses: [],
      },
      expenseIncomingData: [],
    };
  },
  methods: {
    capitalizeStr(str) {
      const modifiedStr = str.toLowerCase();
      let arr = modifiedStr.split(" ");
      for (let i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      const strCapitalized = arr.join(" ");
      return strCapitalized;
    },
    ...mapMutations({
      setForm: SET_FORM,
      setExpenseDetail: SET_EXPENSE_DETAIL,
      resetForm: RESET_FORM,
    }),
    downloadPdf() {
      this.openInNewTab(this.currentPDF.pdf_link);
    },
    onDownloadButtonClick() {
      let self = this;
      this.$store.commit(SET_LOADING, true);
      this.currentPDF = this.form;

      this.src = pdf.createLoadingTask(this.currentPDF.pdf_link);

      this.src.promise.then((pdf) => {
        this.numPages = pdf.numPages;
      });

      setTimeout(() => {
        self.$store.commit(SET_LOADING, false);
        self.$refs["display-pdf-modal"].show();
      }, 2000);
    },
    getTotalValue(value, key) {
      let currentValue = this.cloneForce(value);
      return currentValue.amount;
    },
    getOriginalImage() {
      this.imageLinks = [this.form.image_link_large];
      this.imageIndex = 0;
    },
    onChangeSizeAndColor($event, data) {
      data.body_sizes = null;
      data.amount = 0;
      let orderSizeAndColors = this.form.order_size_and_colors;

      let orderSizeAndColorId = Number($event);
      let orderSizeAndColor = _.cloneDeep(
        orderSizeAndColors.find((item) => +item.id === +orderSizeAndColorId)
      );

      let entranceOrderSizeAndColors = this.expenseIncomingData;

      let incomingAmount = entranceOrderSizeAndColors[orderSizeAndColorId]
        ? entranceOrderSizeAndColors[orderSizeAndColorId].amount
        : 0;

      if (+data.expense_type_id !== 2) {
        let incomingBodySizes = entranceOrderSizeAndColors[orderSizeAndColorId]
          ? entranceOrderSizeAndColors[orderSizeAndColorId].body_sizes
          : [];

        incomingBodySizes = _.map(incomingBodySizes, (value, key) => ({
          key,
          value,
        }));
        incomingBodySizes.forEach((item) => {
          let key = item.key;
          orderSizeAndColor.body_sizes[key].amount =
            orderSizeAndColor.body_sizes[key].amount - item.value;
        });

        data.body_sizes = orderSizeAndColor.body_sizes;
      } else {
        data.total = orderSizeAndColor.total - incomingAmount;
      }

      this.defaultTotals = [];
      let tempData = _.cloneDeep(data);
      let newAmount = tempData.total + 0.05 * tempData.total;

      let bodySizes = _.values(tempData.body_sizes);

      bodySizes.map((item) => {
        item.amount = Number(item.amount) + 0.05 * Number(item.amount);
        return item;
      });

      this.defaultTotals.push({
        id: tempData.id,
        total: newAmount,
        body_sizes: bodySizes,
        order_size_and_color_id: orderSizeAndColorId,
      });
    },
    changeSelectedExpense(expense) {
      this.instructionTypeId = Number(expense.expense.instructionable[0].id);
      this.expense_data.instructionTypeId = Number(
        expense.expense.instructionable[0].id
      );
      this.expense_data.selectedExpenseID = Number(expense.expense_id);
      this.expense_data.typeId = Number(expense.expense.type_id);
      this.expense_data.orderExpenseId = Number(expense.id);

      this.getSupplierCompanies();
      this.getIncomingExpense();
    },
    getInstructionTypes() {
      let self = this;
      this.$store
        .dispatch(GET_ITEMS, {
          url: "api/instruction-type",
          acceptPromise: true,
        })
        .then((result) => {
          if (result.status) {
            self.instructionTypes = result.data;
          }
        });
    },
    closeModal(name = "open_to_settings_table") {
      this.$refs[name].hide();
    },
    onCloseModal() {
      this.instructions = null;
      this.$refs["instructions-modal"].hide();
    },
    showInstructions() {
      let self = this;
      let filters = {
        instruction_type: "O-" + self.id,
      };
      self.$store.commit(SET_LOADING, true);

      self.$store
        .dispatch(GET_ITEMS, {
          url: `api/instructions`,
          filters: filters,
        })
        .then((result) => {
          self.$store.commit(SET_LOADING, false);
          if (result.status) {
            self.instructions = result.data;
            self.$refs["instructions-modal"].show();
          } else {
            self.instructions = null;
          }
        });

      self.$store
        .dispatch(GET_ITEMS, {
          url: `api/orders/${this.id}/order-expenses`,
          filters: filters,
        })
        .then((result) => {
          self.$store.commit(SET_LOADING, false);
          if (result.status) {
            self.expenses = result.data;
          } else {
            self.expenses = null;
          }
        });
    },
    showWaybills(itemId, type = 1, name = "") {
      let self = this;
      if (+type === 1) {
        this.$router.push({
          name: "waybill.detail.index",
          params: {
            id: "O-" + self.id,
            type: type,
            detailId: itemId,
            name: name,
          },
        });
      }
      if (+type === 2) {
        this.$router.push({
          name: "waybill.detail.index",
          params: {
            id: "O-" + self.id,
            type: type,
            detailId: itemId,
            name: name,
          },
        });
      }
      if (+type === 3) {
        let product_id = itemId.order_fabric.id;
        let order_size_and_color_id = itemId.order_size_and_color.id;
        this.$router.push({
          name: "waybill.detail.index",
          params: {
            id: "O-" + self.id,
            type: type,
            detailId: product_id,
            sizeAndColorId: order_size_and_color_id,
            name: name,
          },
        });
      }
    },
    sendExpenseDetail(expenseDetail, expenseDetailIndex) {
      let self = this;
      let payloads = {
        url: "api/order-expense-details/" + expenseDetail.id + "/share",
      };

      this.sweetAlertLoading();
      this.$store
        .dispatch(CREATE_ITEM, payloads)
        .then((response) => {
          self.sweetAlertClose();
          if (response.status) {
            self.sweetAlertSuccess(self.$t("general.success"));
          } else {
            let result = response.data.response;
            if (
              result.hasOwnProperty("data") &&
              result.data.hasOwnProperty("message")
            ) {
              self.sweetAlertError(result.data.message);
            } else {
              self.sweetAlertError(result.data);
            }
          }
        })
        .catch((response) => {
          self.sweetAlertClose();
          let result = response.data.response;
          if (
            result.hasOwnProperty("data") &&
            result.data.hasOwnProperty("message")
          ) {
            self.sweetAlertError(result.data.message);
          } else {
            self.sweetAlertError(result.data);
          }
        });
    },
    cancelExpenseDetail(expenseDetail, expenseDetailIndex) {
      if (this.is_submitting) return;
      this.is_submitting = true;
      let self = this;
      this.sweetAlertConfirm(this.$t("general.are_you_sure")).then((res) => {
        if (res) {
          self.$store
            .dispatch(DELETE_ITEM_BY_ID, {
              url: `api/orders/${self.form.id}/expenses/1/details/${expenseDetail.id}`,
            })
            .then((result) => {
              if (result.status) {
                self.sweetAlertSuccess(self.$t("general.successfully_deleted"));
                if (
                  this.currentOrderExpenseDetail.order_expense_details
                    .length === 1
                ) {
                  this.addNewExpenseDetail();
                }
                this.currentOrderExpenseDetail.order_expense_details.splice(
                  expenseDetailIndex,
                  1
                );
              } else {
                this.sweetAlertError(
                  this.$t("order.cannot_delete_expense_has_waybill")
                );
              }

              self.is_submitting = false;
            });
        } else {
          self.is_submitting = false;
        }
      });
    },
    addNewTarget(index) {
      const i =
        this.currentOrderExpenseDetail.order_expense_details[index]
          .order_expense_detail_targets.length;
      let targetItem =
        this.currentOrderExpenseDetail.order_expense_details[index]
          .order_expense_detail_targets[i - 1];

      targetItem = moment(targetItem.date).add(1, "day");

      const dayNumber = targetItem.isoWeekday();
      if (dayNumber === 7) {
        targetItem.add(1, "days");
      }
      this.currentOrderExpenseDetail.order_expense_details[
        index
      ].order_expense_detail_targets.push({
        date: targetItem.format("YYYY-MM-DD"),
        target: null,
      });
    },
    deleteNewTarget(index, target_index) {
      this.currentOrderExpenseDetail.order_expense_details[
        index
      ].order_expense_detail_targets.splice(target_index, 1);
    },
    changeToEditMode(expenseDetail, expendDetailIndex) {
      expenseDetail.is_editing = true;
    },
    fixedAndUpdateOrderExpenseDetails(data) {
      let amount = 0;
      if (Number(data.expense_type_id) !== 2) {
        let items = _.values(data.body_sizes);
        items.forEach((subItem) => {
          amount = Number(amount) + Number(subItem.amount);
        });
      } else {
        amount = Number(amount) + Number(data.total);
      }
      data.total = Number(amount);
      data.amount = Number(amount);
      data.total_amount = Number(amount);

      data.start_date = moment(data.start_date).format(LARAVEL_DATE_FORMAT);
      data.end_date = moment(data.end_date).format(LARAVEL_DATE_FORMAT);
      data.end_date = moment(data.end_date).format(LARAVEL_DATE_FORMAT);
      data.body_sizes = JSON.stringify(_.cloneDeepWith(data.body_sizes));
      data.order_expense_detail_targets.forEach((subItem) => {
        subItem.date = moment(subItem.date).format(LARAVEL_DATE_FORMAT);
      });

      return data;
    },
    isValidToCreateInstruction(expenseDetailIndex) {
      if (!this.currentOrderExpenseDetail) return false;
      let current =
        this.currentOrderExpenseDetail.order_expense_details[
          expenseDetailIndex
        ];
      let currentExpense = this.form.order_expenses.find(
        (item) => +item.id === +current.order_expense_id
      );
      if (!current || !currentExpense) return false;
      let planned = parseFloat(currentExpense.planned);

      if (Number(current.supplier_company_id) === 0)
        return "select_supplier_company";

      if (Number(current.dispatch_location_id) === 0)
        return "select_dispatch_location";
      if (Number(current.currency_unit_id) === 0) return "select_currency_unit";

      if (current.expiry_id === null) return "select_expiry";
      if (
        Number(this.expense_data.selectedExpenseID) === 17 &&
        Number(current.snap_type_id) === 0
      )
        return "select_snap_type";

      if (Number(current.expense_type_id) !== 2) {
        if (current.body_sizes === {}) return "select_body_size";
      }
      if (!current.order_size_and_color_id) return "select_size_and_color";
      if (Number(current.unit_id) === 0) return "select_unit";
      if (Number(current.price) === 0) return "enter_price";
      if (Number(current.price) > planned) return "price_not_gt_planned";
      if (Number(current.vat_rate_id) === 0) return "select_vat_rate";

      let totalAmount = 0;

      this.currentOrderExpenseDetail.order_expense_details.forEach((item) => {
        let amount = 0;
        if (Number(item.expense_type_id) !== 2) {
          let items = _.values(item.body_sizes);
          items.forEach((subItem) => {
            amount += Number(subItem.amount);
          });
          totalAmount += amount;
        } else {
          totalAmount = item.total;
        }
      });

      const orderExpenseAmount = +this.form.order_expenses.find(
        (orderExpenseItem) => +orderExpenseItem.id === +current.order_expense_id
      ).amount;
      const orderAmount = this.form.amount * orderExpenseAmount;
      const amountWithCoefficient =
        orderAmount + (orderAmount * this.expenseCoefficient) / 100;

      if (+totalAmount > amountWithCoefficient) return "amount_not_gt_order";

      let productAmount = 0;

      let amount = 0;
      if (Number(current.expense_type_id) !== 2) {
        let items = _.values(current.body_sizes);
        items.forEach((subItem) => {
          amount += Number(subItem.amount);
        });
        productAmount += amount;
      } else {
        productAmount = current.total;
      }

      totalAmount = 0;
      current.order_expense_detail_targets.forEach((target) => {
        totalAmount += parseFloat(target.target);
      });

      if (+totalAmount !== +productAmount) return "amount_not_gt_product";

      return true;
    },

    createOrUpdateInstruction(expendDetailIndex) {
      let checkValidations = this.isValidToCreateInstruction(expendDetailIndex);
      if (checkValidations !== true) {
        this.sweetAlertError(this.$t("general." + checkValidations));
        return false;
      }
      let contents = _.cloneDeepWith(this.currentOrderExpenseDetail),
        self = this;
      this.sweetAlertLoading();

      let formData = this.fixedAndUpdateOrderExpenseDetails(
        contents.order_expense_details[expendDetailIndex]
      );

      this.$store
        .dispatch(CREATE_ITEM, {
          url: `api/orders/${this.form.id}/order-expense-details`,
          contents: { order_expense_detail: formData },
        })
        .then((response) => {
          self.sweetAlertClose();
          if (response.status) {
            let item = response.data;
            self.currentOrderExpenseDetail.order_expense_details[
              expendDetailIndex
            ].id = item.id;
            self.currentOrderExpenseDetail.order_expense_details[
              expendDetailIndex
            ].order_pdf_link = item.order_pdf_link;
            self.currentOrderExpenseDetail.order_expense_details[
              expendDetailIndex
            ].order_excel_link = item.order_excel_link;
            self.currentOrderExpenseDetail.order_expense_details[
              expendDetailIndex
            ].is_submitted = true;
            self.currentOrderExpenseDetail.order_expense_details[
              expendDetailIndex
            ].is_editing = false;

            self.sweetAlertSuccess(self.$t("general.successfully_saved"));
          } else {
            let result = response.data.response;
            if (
              result.hasOwnProperty("data") &&
              result.data.hasOwnProperty("message")
            ) {
              self.sweetAlertError(result.data.message);
            } else {
              self.sweetAlertError(result.data);
            }
          }
        });
    },
    getIncomingExpense() {
      let self = this;
      let expenseId = this.expense_data.orderExpenseId;

      let url =
        "api/order/" + this.id + "/order-expense/" + expenseId + "/amount";
      this.$store
        .dispatch(GET_ITEMS, {
          url: url,
          acceptPromise: true,
        })
        .then((result) => {
          if (result.status) {
            self.expenseIncomingData = result.data;

            /*
                    if (self.netIncomingFabricAmount == fabricItem.amount) {
                      self.sweetAlertClose();
                      setTimeout(() => {
                        self.sweetAlertError("general.fabric_requests_completed");
                      }, 100)
                    }*/
          }
        });
    },
    addNewExpenseDetail() {
      let data = _.cloneDeepWith(
        this.expense_details[
          this.expense_data.selectedExpenseID
        ].order_expense_details.slice(-1)[0]
      );
      this.getIncomingExpense();
      let expenseDetailTemplate = {
        id: null,
        is_submitted: false,
        is_editing: true,
        order_size_and_color_id: null,
        order_expense_item_type_id: null,
        supplier_company_id: null,
        dispatch_location_id: null,
        currency_unit_id: "53",
        exchange_rate: "1",
        to_who: "",
        price: data.price,
        body_sizes: {},
        expiry_id: null,
        start_date: moment(),
        end_date: moment(),
        description: null,
        amount_per_parcel: 1,
        instruction_description: null,
        order_pdf_link: null,
        order_excel_link: null,
        snap_type_id: null,
        order_expense_detail_targets: [
          {
            date: moment(),
            target: null,
          },
        ],
      };

      let tmpData = { ...data, ...expenseDetailTemplate };

      this.expense_details[
        this.expense_data.selectedExpenseID
      ].order_expense_details.push(tmpData);
    },
    deleteNewExpenseDetail(index) {
      this.expense_details[
        this.expense_data.selectedExpenseID
      ].order_expense_details.splice(-1);
    },
    onExpenseDetailCurrencyChange(payload) {
      let index = payload.name.split("-")[1],
        newValue = payload.newValue,
        oldValue = payload.oldValue;
      let rate = 1;
      this.expense_details[
        this.expense_data.selectedExpenseID
      ].order_expense_details[index].exchange_rate = rate;

      if (!newValue) return;
      if (newValue[0] in this.options.currencyOptions) {
        rate = Number(
          this.options.currencyRateOptions[
            this.options.currencyOptions[newValue[0]]
          ].rate
        );
      }
      for (
        let i = 0;
        i < this.currentOrderExpenseDetail.order_expense_details.length;
        i++
      ) {
        this.currentOrderExpenseDetail.order_expense_details[
          i
        ].currency_unit_id = newValue[0];
      }
      this.expense_details[
        this.expense_data.selectedExpenseID
      ].order_expense_details[index].exchange_rate = rate;
    },
    convertReturnOrderValueToValidFormData(data) {
      let self = this;
      if (
        Number(data.sample_id) !== 0 &&
        !isNaN(Number(data.sample_id)) &&
        false
      ) {
        this.$store
          .dispatch(GET_ITEM_DETAIL_BY_ID, {
            url: `api/samples/${data.sample_id}`,
            id: data.sample_id,
          })
          .then((result) => {
            if (result.status) {
              self.form.sample = result.data;
            }
          });
      }
      if (Number(data.esc_table_id) !== 0) {
        this.$store
          .dispatch(GET_ITEM_DETAIL_BY_ID, {
            url: `api/esc-tables/${data.esc_table_id}?single=1`,
            id: data.esc_table_id,
          })
          .then((result) => {
            if (result.status) {
              self.form.esc = result.data;
              self.form.esc_table_id = result.data.id;
            }
          });
      }
      data.status_id = data.status_id.toString();
      this.orderDate = data.order_date;
      if ("order_export_dates" in data) {
        data.order_export_dates = data.order_export_dates.map(
          (value, index) => {
            value.export_date = moment(value.export_date, LARAVEL_DATE_FORMAT);
            return value;
          }
        );
      }
      this.setExpenseDetail({});
      let body_sizes = [];
      if ("order_size_and_colors" in data) {
        data.order_size_and_colors = data.order_size_and_colors.map(
          (value, index) => {
            try {
              value.body_sizes = JSON.parse(value.body_sizes);
              body_sizes.push({ ...value.body_sizes });
            } catch (e) {
              console.error(e);
              value.body_sizes = {};
              body_sizes.push({});
            }

            return value;
          }
        );
      }

      if ("order_fabrics" in data) {
        data.order_fabrics = data.order_fabrics.map((value, index) => {
          value.deadline_start = moment(
            value.deadline_start,
            LARAVEL_DATE_FORMAT
          );
          value.deadline_end = moment(value.deadline_end, LARAVEL_DATE_FORMAT);
          value.selected_order_fabric_compositions = [];

          if ("order_fabric_compositions" in value) {
            value.order_fabric_compositions =
              value.order_fabric_compositions.map((fabric_composition, i) => {
                value.selected_order_fabric_compositions.push(
                  fabric_composition.fabric_composition_id
                );
                return fabric_composition;
              });
          }
          return value;
        });
      }

      if ("order_expenses" in data) {
        data.selected_expenses = [];
        data.order_expenses = data.order_expenses.map((value, index) => {
          data.selected_expenses.push(value.expense_id);
          if (value.expense_id in this.options.expensesOptions) {
            value.name = this.options.expensesOptions[value.expense_id].name;
            value.is_default = 0;
          } else if (value.expense_id in this.options.optionalExpenseOptions) {
            value.name =
              this.options.optionalExpenseOptions[value.expense_id].name;
            value.is_default = 1;
          }
          this.expense_details[value.expense_id] = {};
          if (
            "order_expense_details" in value &&
            value.order_expense_details.length > 0
          ) {
            this.expense_details[value.expense_id].order_expense_details =
              value.order_expense_details.map((order_expense_detail, index) => {
                order_expense_detail.start_date = moment(
                  order_expense_detail.start_date,
                  LARAVEL_DATE_FORMAT
                );
                order_expense_detail.end_date = moment(
                  order_expense_detail.end_date,
                  LARAVEL_DATE_FORMAT
                );
                order_expense_detail.select_dispatch_locations = [];
                order_expense_detail.expense_type_id = value.expense.type_id;
                order_expense_detail.is_submitted = true;
                order_expense_detail.is_editing = false;
                order_expense_detail.pdf_link =
                  order_expense_detail.order_pdf_link;

                if (
                  "order_expense_detail_dispatch_locations" in
                    order_expense_detail &&
                  order_expense_detail.order_expense_detail_dispatch_locations
                    .length > 0
                ) {
                  order_expense_detail.order_expense_detail_dispatch_locations.forEach(
                    (dispatch_location_detail) => {
                      order_expense_detail.select_dispatch_locations.push(
                        dispatch_location_detail.dispatch_location_id
                      );
                    }
                  );
                }

                try {
                  order_expense_detail.body_sizes = JSON.parse(
                    order_expense_detail.body_sizes
                  );
                } catch (e) {
                  order_expense_detail.body_sizes = {};
                }

                if (
                  !("order_expense_detail_targets" in order_expense_detail) &&
                  order_expense_detail.order_expense_detail_targets
                ) {
                  order_expense_detail.order_expense_detail_targets = {
                    date: moment(),
                    target: null,
                  };
                } else {
                  order_expense_detail.order_expense_detail_targets =
                    order_expense_detail.order_expense_detail_targets.map(
                      (target) => {
                        target.date = moment(
                          target.date,
                          LARAVEL_DATE_TIME_FORMAT
                        );
                        return target;
                      }
                    );
                }
                return order_expense_detail;
              });
          } else {
            let expense_body_size = {},
              total = 0;
            body_sizes.forEach((body_size, i) => {
              for (let key in body_size) {
                if (key in expense_body_size) {
                  expense_body_size[key].amount =
                    Number(expense_body_size[key].amount) +
                    Number(body_size[key].amount);
                  expense_body_size[key].max_amount =
                    Number(expense_body_size[key].amount) +
                    Number(body_size[key].amount);
                } else {
                  expense_body_size[key] = { ...body_size[key] };
                }
                total += Number(body_size[key].amount);
              }
            });

            for (let key in expense_body_size) {
              expense_body_size[key].amount = expense_body_size[key].amount;
              expense_body_size[key].max_amount = expense_body_size[key].amount;
            }

            let tmpExpenseTypeId = 1;
            if (
              value.expense_id &&
              value.expense_id in this.options.expensesOptions
            ) {
              tmpExpenseTypeId =
                this.options.expensesOptions[value.expense_id].type_id;
            }

            if (
              value.expense_id &&
              value.expense_id in this.options.optionalExpenseOptions
            ) {
              tmpExpenseTypeId =
                this.options.optionalExpenseOptions[value.expense_id].type_id;
            }

            this.expense_details[value.expense_id] = {
              order_expense_details: [
                {
                  id: null,
                  is_submitted: false,
                  is_editing: true,
                  order_expense_id: value.id,
                  expense_type_id: tmpExpenseTypeId,
                  order_size_and_color_id: null,
                  order_expense_item_type_id: null,
                  supplier_company_id: null,
                  dispatch_location_id: null,
                  currency_unit_id: "53",
                  exchange_rate: "1",
                  to_who: "",
                  expiry_id: null,
                  start_date: moment(),
                  end_date: moment(),
                  description: null, // nullable
                  order_pdf_link: null,
                  order_excel_link: null,
                  snap_type_id: null, // nullable if not cit cit
                  body_sizes: null,
                  total: 0,
                  unit_id: value.unit_id,
                  price: value.planned,
                  discount: null,
                  vat_rate_id: null,
                  order_expense_detail_targets: [
                    {
                      date: moment(),
                      target: null,
                    },
                  ],
                },
              ],
            };
          }
          // This line of code make expense_details reactive in vue
          this.setExpenseDetail({ ...this.expense_details });
          return value;
        });
      }

      if (data.hasOwnProperty("csr") && data.csr) {
        if (data.csr && !data.csr.disney_licences) {
          data.csr.disney_licences = {
            license_id: null,
            disney_id: null,
          };
        }

        if (!data.csr.join_life) {
          data.csr.join_life = [];
        }

        this.csr = data.csr;
      }

      data = { ...this.form, ...data };
      this.setForm(data);

      self.imageLinks = [data.image_link];

      this.getSupplierCompanies();
    },
    removeSizeFromList(index, key) {
      let self = this;
      this.sweetAlertConfirm(this.$t("general.are_you_sure")).then((result) => {
        if (result) {
          let temp = { ...self.form.order_size_and_colors[index].body_sizes };
          delete temp[key];
          self.$set(self.form.order_size_and_colors[index], "body_sizes", temp);
        }
      });
    },
    getSupplierCompanies() {
      let content = _.clone(this.csr);
      content.company_id = this.form.company_id
        ? this.form.company_id.toString()
        : null;

      if (this.instructionTypeId) {
        content.instructionTypeId = this.instructionTypeId;
      } else {
        delete content.instructionTypeId;
      }

      let self = this;
      this.$store
        .dispatch(CREATE_ITEM, {
          url: "api/csr/supplier-companies",
          contents: content,
        })
        .then((response) => {
          if (response.status) {
            let items = [];
            response.data.forEach((item) => {
              items.push({
                id: item.supplier_company.id,
                name: item.supplier_company.name,
              });
            });

            self.options.supplierCompanyOptions =
              self.convertArrayToObjectByKey(items, "id", "name");
            self.csrSupplierCompanies = response.data;
          }
        });
    },
    submitOrderCsr() {
      if (!this.csr.join_life.length) {
        this.sweetAlertError(this.$t("csr.validations.join_life"));
        return false;
      }
      /*
         if (!this.csr.disney_licences.disney_id) {
             this.sweetAlertError(this.$t('csr.validations.disney_id'));
             return false;
           }

           if (this.csr.disney_licences.disney_id == 1 && !this.csr.disney_licences.license_id) {
             this.sweetAlertError(this.$t('csr.validations.license_id'));
             return false;
           }
     */
      if (this.id) {
        let contents = {
          csr: this.csr,
        };

        this.$store.dispatch(UPDATE_ITEM_BY_ID, {
          url: "api/orders/" + this.id + "/csr",
          contents: contents,
          showLoading: false,
        });
      }
      this.currentActiveTab = 1;
    },
    submitForm() {
      if (this.is_submitting) return false;
      this.is_submitting = true;
      let self = this;
      this.sweetAlertLoading();

      let form = this.cloneForce(this.form);
      form.sale_price_currency_unit_id = form.currency_unit_id;
      for (let i = 0; i < form.order_export_dates.length; i++) {
        form.order_export_dates[i].export_date = moment(
          form.order_export_dates[i].export_date
        ).format(LARAVEL_DATE_FORMAT);
      }
      for (let i = 0; i < form.order_fabrics.length; i++) {
        form.order_fabrics[i].deadline_start = moment(
          form.order_fabrics[i].deadline_start
        ).format(LARAVEL_DATE_FORMAT);
        form.order_fabrics[i].deadline_end = moment(
          form.order_fabrics[i].deadline_end
        ).format(LARAVEL_DATE_FORMAT);
        form.order_fabrics[i].planned =
          Number(form.order_fabrics[i].pastal) *
          Number(form.order_fabrics[i].unit_price);
      }
      for (let i = 0; i < form.order_size_and_colors.length; i++) {
        form.order_size_and_colors[i].total = this.sizeAndColorTotalByIndex(i);
        form.order_size_and_colors[i].export_total = 0;
        let tempBodySize = _.cloneDeepWith(
          form.order_size_and_colors[i].body_sizes
        );
        for (let key in tempBodySize) {
          if (Number(tempBodySize[key].amount) === 0) delete tempBodySize[key];
        }
        let temp = JSON.stringify(tempBodySize);
        form.order_size_and_colors[i] = {
          ...form.order_size_and_colors[i],
          body_sizes: temp,
        };
      }

      form.csr = this.csr;
      form.esc_table_id = this.esc_id;
      let contents = new FormData();
      for (let key in form) {
        if (key === "image") continue;
        this.createFormDataForList(contents, key, form[key]);
      }
      if (form.image) {
        contents.append("image", form.image);
      }

      this.$store
        .dispatch(CREATE_ITEM, {
          url: "api/orders",
          contents: contents,
          showLoading: false,
        })
        .then((result) => {
          if (result.status) {
            self.form.is_submitted = true;
            self.form.image = null;
            self.convertReturnOrderValueToValidFormData(result.data);
            self.sweetAlertClose();
            self.sweetAlertSuccess(self.$t("general.successfully_created"));
            self.is_submitting = false;
            self.$router.push({ name: "order.index" });
          } else {
            self.isSubmitting = false;
            self.form.is_submitted = true;
            self.is_submitting = false;

            let response = result.data.response;
            if (
              response.hasOwnProperty("data") &&
              response.data.hasOwnProperty("message")
            ) {
              self.sweetAlertError(response.data.message);
            } else {
              self.sweetAlertError(response.data);
            }
          }
        })
        .catch((result) => {
          self.isSubmitting = false;
          self.$store.commit(SET_LOADING, false);
          self.sweetAlertClose();
        });
    },
    updateOrder() {
      if (this.is_submitting) return false;
      this.is_submitting = true;
      let self = this;
      this.sweetAlertLoading();
      let form = this.cloneForce(this.form);
      form.order_expenses.map((item, index) => {
        delete item.order_expense_details;
      });
      delete form.esc;
      delete form.csr;
      delete form.status;
      delete form.company;
      delete form.order_fabric_requirements.order_fabric;
      delete form.order_fabric_requirements.order_size_and_color;

      form.sale_price_currency_unit_id = form.currency_unit_id;
      for (let i = 0; i < form.order_export_dates.length; i++) {
        form.order_export_dates[i].export_date = moment(
          form.order_export_dates[i].export_date
        ).format(LARAVEL_DATE_FORMAT);
      }
      for (let i = 0; i < form.order_fabrics.length; i++) {
        form.order_fabrics[i].deadline_start = moment(
          form.order_fabrics[i].deadline_start
        ).format(LARAVEL_DATE_FORMAT);
        form.order_fabrics[i].deadline_end = moment(
          form.order_fabrics[i].deadline_end
        ).format(LARAVEL_DATE_FORMAT);
        form.order_fabrics[i].planned =
          Number(form.order_fabrics[i].pastal) *
          Number(form.order_fabrics[i].unit_price);
      }
      for (let i = 0; i < form.order_size_and_colors.length; i++) {
        form.order_size_and_colors[i].total = this.sizeAndColorTotalByIndex(i);
        let tempBodySize = _.cloneDeepWith(
          form.order_size_and_colors[i].body_sizes
        );
        for (let key in tempBodySize) {
          if (Number(tempBodySize[key].amount) === 0) delete tempBodySize[key];
        }
        let temp = JSON.stringify(tempBodySize);
        form.order_size_and_colors[i] = {
          ...form.order_size_and_colors[i],
          body_sizes: temp,
        };
      }

      form.csr = this.csr;

      if (this.esc_id) {
        form.esc_table_id = this.esc_id;
      }

      let contents = new FormData();
      for (let key in form) {
        if (key === "image") continue;
        this.createFormDataForList(contents, key, form[key]);
      }
      if (this.form.image) {
        contents.append("image", this.form.image);
      }
      this.$store
        .dispatch(CREATE_ITEM, {
          url: "api/orders/" + this.id,
          contents: contents,
          showLoading: false,
        })
        .then((result) => {
          self.isSubmitting = false;
          self.form.is_submitted = true;

          if (result.status) {
            self.form.image = null;
            self.convertReturnOrderValueToValidFormData(result.data);
            self.sweetAlertSuccess(self.$t("general.successfully_created"));
            self.$router.push({ name: "order.index" });
          } else {
            let response = result.data.response;
            if (
              response.hasOwnProperty("data") &&
              response.data.hasOwnProperty("message")
            ) {
              self.sweetAlertError(response.data.message);
            } else {
              self.sweetAlertError(response.data);
            }
          }
          self.sweetAlertClose();
        })
        .catch((result) => {
          self.is_submitting = false;
          self.form.is_submitted = true;
          self.$store.commit(SET_LOADING, false);
          self.sweetAlertClose();
        });
    },
    submitAndContinueSupplier() {
      this.currentActiveTab = 2;
    },
    redirectToSelectSample() {
      let query = {
        type: this.type,
        id: this.id,
        redirect: 1,
        redirectName: "order",
        ...this.$route.query,
      };
      this.$router.replace({
        name: "sample",
        query: query,
      });
    },
    redirectToSelectESC() {
      let query = {
        type: this.type,
        id: this.id,
        redirect: 1,
        in_use: 0,
        redirectName: "order",
        ...this.$route.query,
      };
      this.$router.replace({
        name: "esc-meeting.index",
        query: query,
      });
    },
    onChangeOrderImage() {
      this.form.image = this.$refs.orderImage.files[0];
      let imageHolder = $("#image_holder");
      imageHolder.attr("src", URL.createObjectURL(this.form.image));
    },
    addExportDateInfo() {
      this.form.order_export_dates.push({
        export_date: moment(),
        amount: null,
      });
    },
    deleteExportDateInfo(index) {
      this.form.order_export_dates.splice(index, 1);
    },
    addNewSizeAndColor() {
      let item = JSON.parse(
        JSON.stringify(this.form.order_size_and_colors.slice(-1)[0])
      );
      item.id = null;
      this.form.order_size_and_colors.push(item);
    },
    deleteNewSizeAndColor(index) {
      this.form.order_size_and_colors.splice(index, 1);
    },
    addNewOrderFabric() {
      this.form.order_fabrics.push({
        id: null,
        deadline_start: this.form.order_fabrics.slice(-1)[0].deadline_start,
        deadline_end: this.form.order_fabrics.slice(-1)[0].deadline_end,
        type: null,
        width: null,
        weight: null,
        pastal: null,
        unit_price: null,
        unit_id: this.options.unitOptions3.find((item) => item.code === "KG")
          .id,
        planned: null,
        actual: null,
        selected_order_fabric_compositions: [],
        order_fabric_compositions: [],
      });
      /*      this.form.order_size_and_colors.push({
              order_fabric_id: uuid,
              color_number: null,
              color_name: null,
              body_sizes: _.cloneDeepWith(this.defaultSizeJSON),
              total: null,
              export_total: null,
            })*/
    },
    deleteNewOrderFabric(index) {
      this.form.order_fabrics.splice(index, 1);
    },
    onExpensesSave(payload) {
      let oldValue = payload.oldValue,
        newValue = payload.newValue,
        current = this.form.order_expenses;
      for (let i = 0; i < oldValue.length; i++) {
        if (!newValue.includes(oldValue[i])) {
          for (let j = 0; j < current.length; j++) {
            if (Number(current[j].expense_id) === Number(oldValue[i])) {
              current.splice(j, 1);
              break;
            }
          }
        }
      }
      for (let i = 0; i < newValue.length; i++) {
        if (
          !oldValue.includes(newValue[i]) &&
          !this.form.selected_expenses.includes(newValue[i])
        ) {
          this.form.order_expenses.push({
            actual: null,
            amount: null,
            planned: null,
            currency_unit_id: Object.keys(
              this.options.currencyOptions
            )[0].toString(),
            unit_id: this.options.unitOptions3
              .find((item) => item.code === "ADET")
              .id.toString(),
            expense_id: newValue[i],
            is_default: 0,
            name: this.options.expensesOptions[newValue[i]].name,
          });
        }
      }
    },
    DeleteExpensesSave(index) {
      this.form.order_expenses.splice(index, 1);
    },
    onCompanyChange(payload) {
      if (this.form.company_id === null || this.form.company_id === undefined)
        return;
      if (
        (payload.oldValue === null || payload.oldValue === undefined) &&
        this.id
      )
        return;
      let self = this;
      this.$store
        .dispatch(GET_ITEMS, {
          url: "api/body-sizes",
          filters: {
            company_id: this.form.company_id,
          },
        })
        .then((result) => {
          if (result.status) {
            self.options.bodySizeOptions = self.convertArrayToObjectByKey(
              result.data,
              "id"
            );
            let json = {};
            for (let key in self.options.bodySizeOptions) {
              json[key] = {
                body_size_id: key,
                amount: null,
                name: self.getValueByDottedKey(
                  self.options.bodySizeOptions[key],
                  "translations.0.name"
                ),
              };
            }
            self.defaultSizeJSON = json;
            for (let i = 0; i < self.form.order_size_and_colors.length; i++) {
              self.form.order_size_and_colors[i].body_sizes = {
                ...self.defaultSizeJSON,
              };
            }
          }
        });
    },
    onOrderFabricCompositionChange(payload) {
      let index = payload.name.split("-")[1],
        oldValue = payload.oldValue,
        newValue = payload.newValue;
      let currentFabricComposition = this.form.order_fabrics[index];

      for (let i = 0; i < oldValue.length; i++) {
        if (!newValue.includes(oldValue[i])) {
          for (
            let j = 0;
            j < currentFabricComposition.order_fabric_compositions.length;
            j++
          ) {
            if (
              Number(
                currentFabricComposition.order_fabric_compositions[j]
                  .fabric_composition_id
              ) === Number(oldValue[i])
            ) {
              currentFabricComposition.order_fabric_compositions.splice(j, 1);
              break;
            }
          }
        }
      }
      let n =
        newValue.length -
        currentFabricComposition.order_fabric_compositions.length;
      let remaining = 0;
      for (
        let i = 0;
        i < currentFabricComposition.order_fabric_compositions.length;
        i++
      ) {
        remaining += Number(
          currentFabricComposition.order_fabric_compositions[i].amount
        );
      }
      let newPercent = 0;
      if (100 - remaining !== 0 || n > 0) {
        newPercent = ((100 - remaining) / n).toFixed(0);
      }
      if (Number(newPercent) < 0) newPercent = 0;

      for (let i = 0; i < newValue.length; i++) {
        if (
          !oldValue.includes(newValue[i]) &&
          !currentFabricComposition.selected_order_fabric_compositions.includes(
            newValue[i]
          )
        ) {
          currentFabricComposition.order_fabric_compositions.push({
            fabric_composition_id: newValue[i],
            amount: newPercent,
            valid: false,
          });
        }
      }
    },
    setup() {
      if (this.$route.query === this.$route.query) {
        this.resetForm();
      }
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: this.$t("general.dashboard"), route: "dashboard" },
        { title: this.$t("order.orders"), route: "order.index" },
        {
          title: this.id
            ? this.$t("order.edit_order")
            : this.type === 1
            ? this.$t("order.create_order_from_esc")
            : this.$t("order.create_order_from_sample"),
        },
      ]);
      this.sweetAlertLoading();
      let self = this,
        promises = [];

      if (this.esc_id !== null) {
        this.form.esc_table_id = this.esc_id;
      }

      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/companies",
        })
      );
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/statuses",
          filters: {
            type_id: 2,
          },
        })
      );
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/currency",
        })
      );
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/users/qc-staff",
        })
      );
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/fabric-compositions",
        })
      );
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/expenses",
        })
      );
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/units",
        })
      );
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/snap-types",
        })
      );
      promises.push(
        this.$store.dispatch(CREATE_ITEM, {
          url: "api/csr/supplier-companies",
        })
      );
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/currency-rate",
        })
      );
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/vat-rates",
          filters: {},
        })
      );
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/fabric-yarn-types",
        })
      );
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/fabric-knit-types",
        })
      );
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/fabric-types",
        })
      );
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/fabric-process",
        })
      );
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/fabric-additions",
        })
      );
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/press-types",
        })
      );
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/expiries",
        })
      );
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/product-process",
        })
      );

      Promise.all(promises).then((results) => {
        if (results[0].status) {
          self.options.customerOptions = self.convertArrayToObjectByKey(
            results[0].data,
            "id",
            "name"
          );
        }
        if (results[1].status) {
          self.options.orderStatusOptions = self.convertArrayToObjectByKey(
            results[1].data,
            "id",
            "translations.0.name"
          );
          self.form.status_id = Object.keys(
            self.options.orderStatusOptions
          )[0].toString();
        }
        if (results[2].status) {
          let items = results[2].data;
          self.options.currencyOptions = self.convertArrayToObjectByKey(
            items,
            "id",
            "code"
          );
          self.form.currency_unit_id = items.find(
            (item) => item.code == "TRY"
          ).id;
          self.options.currencyRawOptions = items;
        }
        if (results[3].status) {
          self.options.qcUserOptions = self.convertArrayToObjectByKey(
            results[3].data,
            "id",
            "name"
          );
        }
        if (results[4].status) {
          self.options.fabricCompositionOptions =
            self.convertArrayToObjectByKey(
              results[4].data,
              "id",
              "translations.0.name"
            );
        }
        if (results[6].status) {
          self.options.unitOptions = self.convertArrayToObjectByKey(
            results[6].data,
            "id",
            "translations.0.name"
          );
          let tmpUnits = self.convertArrayToObjectByKey(
            results[6].data,
            "id",
            "translations.0.name"
          );
          self.options.unitOptions2 = tmpUnits;
          self.options.unitOptions3 = results[6].data;
        }
        if (results[5].status) {
          let temp = results[5].data;
          self.form.order_expenses = [];
          let tempExpenses = [],
            tempOptionalExpenses = [],
            tempFormOptionExpenses = [];
          for (let i = 0; i < temp.length; i++) {
            {
              if (Number(temp[i].is_default) === 1) {
                self.form.order_expenses.push({
                  planned: null,
                  actual: null,
                  amount: null,
                  unit_id: this.options.unitOptions3
                    .find((item) => item.code === "ADET")
                    .id.toString(),
                  expense_id: temp[i].id,
                  is_default: Number(temp[i].is_default),
                  currency_unit_id: Object.keys(
                    self.options.currencyOptions
                  )[0].toString(),
                  name: temp[i].translations[0].name,
                  type_id: temp[i].type_id,
                });
                tempOptionalExpenses.push(temp[i]);
                tempFormOptionExpenses.push({
                  expense_id: temp[i].id,
                  amount: null,
                  name: temp[i].translations[0].name,
                  type_id: temp[i].type_id,
                });
              } else {
                tempExpenses.push({
                  expense_id: temp[i].id,
                  amount: null,
                  name: temp[i].translations[0].name,
                  type_id: temp[i].type_id,
                });
              }
            }
          }
          self.options.expensesOptions = self.convertArrayToObjectByKey(
            tempExpenses,
            "expense_id"
          );
          self.options.optionalExpenseOptions = self.convertArrayToObjectByKey(
            tempFormOptionExpenses,
            "expense_id"
          );
          // self.options.form.optionalExpenses = [...tempFormOptionExpenses];

          if (self.esc_id !== 0 && !isNaN(self.esc_id)) {
            self.$store
              .dispatch(GET_ITEMS, {
                url: `api/esc-tables/${self.esc_id}?single=1`,
              })
              .then((result) => {
                if (result.status) {
                  self.form.image_link = result.data.image_link;
                  self.form.image_link_large = result.data.image_link_large;
                  self.form.esc_id = result.data.id;
                  self.form.esc = result.data;
                  self.form.company_id = result.data.company_id;
                  self.form.model = result.data.model;
                  self.form.currency_unit_id = result.data.currency_unit_id;
                  self.form.description = result.data.description;
                  self.form.instruction_description =
                    result.data.instruction_description;
                  self.form.amount_per_parcel = result.data.amount_per_parcel;

                  if (!self.id) {
                    let tempExpenses = self.convertArrayToObjectByKey(
                      result.data.esc_table_expenses,
                      "expense_id",
                      "amount"
                    );
                    let tempOrderExpenses = self.convertArrayToObjectByKey(
                      [...self.form.order_expenses],
                      "expense_id"
                    );
                    for (let key in tempExpenses) {
                      if (key in tempOrderExpenses) {
                        let index = Object.keys(tempOrderExpenses).indexOf(key);
                        self.form.order_expenses[index].planned =
                          tempExpenses[key];
                        self.form.order_expenses[index].amount = 1;
                      } else {
                        let name = "";
                        if (key in self.options.expensesOptions) {
                          name = self.options.expensesOptions[key].name;
                        } else {
                          name = self.options.optionalExpenseOptions[key].name;
                        }
                        self.form.order_expenses.push({
                          actual: null,
                          amount: 1,
                          planned: tempExpenses[key],
                          currency_unit_id: Object.keys(
                            this.options.currencyOptions
                          )[0].toString(),
                          unit_id: this.options.unitOptions3
                            .find((item) => item.code === "ADET")
                            .id.toString(),
                          expense_id: key,
                          is_default: 0,
                          name: name,
                        });
                      }
                    }
                    if (result.data.fabric_calculations.length > 0) {
                      self.form.order_fabrics = [];
                      result.data.fabric_calculations.forEach(
                        (fabric, index) => {
                          fabric.start_date = moment(fabric.start_date).format(
                            LARAVEL_DATE_FORMAT
                          );
                          fabric.end_date = moment(fabric.end_date).format(
                            LARAVEL_DATE_FORMAT
                          );
                          fabric.type = fabric.fabric;
                          fabric.pastal = fabric.consumption;
                          fabric.unit_price = fabric.price;
                          fabric.unit_id = this.options.unitOptions3
                            .find((item) => item.id === +fabric.unit_id)
                            .id.toString();
                          fabric.planned = null;
                          fabric.actual = null;
                          fabric.selected_order_fabric_compositions = [];
                          fabric.order_fabric_compositions = [];
                          self.form.order_fabrics.push({ ...fabric });
                        }
                      );
                    }
                  }
                }
              });
          }
          if (self.sample_id !== null && !isNaN(self.sample_id)) {
            self.form.sample_id = this.sample_id;
            self.$store
              .dispatch(GET_ITEM_DETAIL_BY_ID, {
                url: `api/samples/${this.sample_id}`,
                id: self.sample_id,
              })
              .then((result) => {
                if (result.status) {
                  self.form.sample = result.data;

                  // TODO: get fabrics
                  if (
                    !self.id &&
                    result.data.sample_product_informations.length > 0
                  ) {
                    self.form.order_fabrics = [];
                    result.data.sample_product_informations.forEach(
                      (fabric) => {
                        fabric.order_id = null;
                        fabric.deadline_start = moment();
                        fabric.deadline_end = moment();
                        fabric.type = fabric.fabric_type.translations[0].name;
                        fabric.pastal = null;
                        fabric.unit_id = this.options.unitOptions3
                          .find((item) => item.id === +fabric.unit_id)
                          .id.toString();
                        fabric.planned = null;
                        fabric.actual = null;
                        fabric.selected_order_fabric_compositions = [];
                        fabric.order_fabric_compositions = [];

                        if (
                          "fabric_composition_sample_product_informations" in
                          fabric
                        ) {
                          fabric.fabric_composition_sample_product_informations.forEach(
                            (composition) => {
                              fabric.selected_order_fabric_compositions.push(
                                composition.fabric_composition_id
                              );
                              fabric.order_fabric_compositions.push({
                                fabric_composition_id:
                                  composition.fabric_composition_id,
                                amount: composition.percent,
                                valid: false,
                              });
                            }
                          );
                        }

                        self.form.order_fabrics.push({ ...fabric });
                      }
                    );
                  }
                }
              });
          }
          if (self.id) {
            self.form.is_submitted = true;
            self.currentActiveTab = 4;

            self.getInstructionTypes();
            self.$store
              .dispatch(GET_ITEM_DETAIL_BY_ID, {
                url: `api/orders/${self.id}`,
              })
              .then((result) => {
                if (result.status) {
                  self.convertReturnOrderValueToValidFormData(result.data);
                }
              });
          } else {
            self.form.fabric_supply_type_id = null;
          }
        }

        if (results[8].status) {
          self.options.dispatchLocationOptions = self.convertArrayToObjectByKey(
            results[8].data,
            "supplier_company_id",
            "supplier_company.name"
          );
        }
        if (results[9].status) {
          self.options.currencyRateOptions = results[9].data.data;
        }
        if (results[10].status) {
          self.options.VATRateOptionsRaw = results[10].data;
        }
        if (results[11].status) {
          self.options.fabricYarnTypeOptions = self.convertArrayToObjectByKey(
            results[11].data,
            "id",
            "translations.0.name"
          );
        }
        if (results[12].status) {
          self.options.fabricKnitTypeOptions = self.convertArrayToObjectByKey(
            results[12].data,
            "id",
            "translations.0.name"
          );
        }
        if (results[13].status) {
          self.options.fabricTypeOptions = self.convertArrayToObjectByKey(
            results[13].data,
            "id",
            "translations.0.name"
          );
        }
        if (results[14].status) {
          self.options.fabricProcessTypeOptions =
            self.convertArrayToObjectByKey(
              results[14].data,
              "id",
              "translations.0.name"
            );
        }
        if (results[15].status) {
          self.options.fabricAdditionOptions = self.convertArrayToObjectByKey(
            results[15].data,
            "id",
            "translations.0.name"
          );
        }
        if (results[16].status) {
          self.options.fabricPrintTypeOptions = self.convertArrayToObjectByKey(
            results[16].data,
            "id",
            "translations.0.name"
          );
        }
        if (results[17].status) {
          self.options.expirationTypeOptions = self.convertArrayToObjectByKey(
            results[17].data,
            "id"
          );
        }
        if (results[18].status) {
          self.options.productProcesses = self.convertArrayToObjectByKey(
            results[18].data,
            "id",
            "translations.0.name"
          );
        }
        if (results[7].status) {
          self.options.snapTypeOptions = self.convertArrayToObjectByKey(
            results[7].data,
            "id",
            "translations.0.name"
          );
        }
        self.sweetAlertClose();
      });
    },
  },
  mounted() {
    if (!this.isUserGranted("Order", ["create", "update", "view"])) {
      return false;
    }

    this.setup();
  },
  watch: {
    vatRateOptions(value) {
      if (value) {
        this.options.VATRateOptions = value;
      }
    },
    $route() {
      this.id = this.$route.params.id;
      this.type = Number(this.$route.params.type);
      this.esc_id = Number(this.$route.query.esc_id);
      this.sample_id = Number(this.$route.query.sample_id);
      this.setup();
    },
    "form.order_expenses": {
      handler: function (newValue, oldValue) {
        if (newValue === undefined || oldValue === undefined) return;
        newValue.map((item) => {
          item.planned = item.planned
            ? item.planned.replace(",", ".")
            : item.planned;
          return item;
        });
      },
      deep: true,
    },
    "form.order_fabrics": {
      handler: function (newValue, oldValue) {
        newValue.map((item) => {
          item.pastal = item.pastal
            ? item.pastal.replace(",", ".")
            : item.pastal;
          item.unit_price = item.unit_price
            ? item.unit_price.replace(",", ".")
            : item.unit_price;
          return item;
        });
      },
      deep: true,
    },
    "form.company_id"(newValue, oldValue) {
      this.onCompanyChange({ newValue, oldValue });
    },
    currentActiveTab(value) {
      this.instructionTypeId = null;
      if (Number(value) === 2 && this.expense_data.selectedExpenseID === null) {
        this.expense_data.selectedExpenseID = Number(
          this.form.order_expenses[0].expense_id
        );
        this.expense_data.typeId = Number(
          this.form.order_expenses[0].expense.type_id
        );
        this.expense_data.orderExpenseId = Number(
          this.form.order_expenses[0].id
        );
        this.instructionTypeId = Number(
          this.form.order_expenses[0].expense.instructionable[0].id
        );
        this.expense_data.instructionTypeId = Number(
          this.form.order_expenses[0].expense.instructionable[0].id
        );
        this.getSupplierCompanies();
      }
    },
    activeTab(value) {
      this.instructionTypeId = null;

      if (Number(value) === 1) {
        this.expense_data.selectedExpenseID = Number(
          this.form.order_expenses[0].expense_id
        );
        this.expense_data.typeId = Number(
          this.form.order_expenses[0].expense.type_id
        );
        this.expense_data.orderExpenseId = Number(
          this.form.order_expenses[0].id
        );
        this.instructionTypeId = Number(
          this.form.order_expenses[0].expense.instructionable[0].id
        );
        this.expense_data.instructionTypeId = Number(
          this.form.order_expenses[0].expense.instructionable[0].id
        );
      }

      if (Number(value) === 2) {
        this.instructionTypeId = null;
      }

      this.getSupplierCompanies();
    },
    "csr.join_life": {
      handler: function (newValue, oldValue) {
        if (newValue) {
          if (newValue.includes("1")) {
            if (newValue.length > 1) {
              this.csr.join_life = ["1"];
            }
          }
        }
      },
      deep: true,
    },
  },
};
</script>
<style>
@media only screen and (max-width: 760px) {
  .test {
    border: 1px solid green;
  }

  .use-font {
    font-family: "Poppins", sans-serif !important;
    color: #101010;
  }

  .main_wrapper {
    display: flex;
    position:relative;
    flex-direction: column;
    height: auto !important;
    min-height: 90vh !important;
    font-family: "Poppins", sans-serif !important;
  }

  .card_top {
    display: flex;
    justify-content: space-between;
    max-height: 23vh;
    width:100%;
    margin: 0 !important;
  }

  .card_top .img-div {
    position:relative;
    border-radius: 12px;
    background-color: #dedede;
    height: auto;
    width:60%;
  }

  .card_top .img-div img {
    display: inline;
    width: 100%;
    height: 100%;
  }

  .card_top .img-div .plus_icon {
    position: absolute;
    bottom: 6%;
    right: 6%;
  }

  .card_top .qr-div {
    display:flex;
    align-items: center;
    justify-content: center;
    width:35%;
    height:100px;
    border-radius: 4px;
    background-color: #dedede;
  }


  /*Card Center */
  .card_center {
    display:flex;
    width: 100%;
    margin-top:24px;
    font-size: 10px;
  }

  .card_center .center_left {
    width:55%;
    padding-right: 8px;
  }
  .card_center .center_right {
    width:45%;
    padding-right: 8px;
  }

  .card_center .center_left .center_left_items {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .card_center .center_right .center_right_items {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  
  .card_center .center_left .center_left_items .menu_label,
  .card_center .center_right .center_right_items .menu_label {
    color: #101010;
    font-weight: 600;
  }

  /* Nav */
  .order_menu_x {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  border-bottom: 1px solid rgb(182, 187, 182);
  margin: 12px 0 0 0 !important ;
}

.order_menu_x .order_menu_item_x {
  display: inline-block !important;
  font-size: 2.5vw !important;
  color: #757070;
  padding: 4px 8px;
}

.order_menu_x .order_menu_item_x_active {
  border-bottom: 2px solid #101010;
  color: #101010;
  font-weight: bolder;
}

/*Bottom Buttons */
.bottom-btns {
    position:absolute;
    bottom:0;
    left:50%;
    transform: translate(-50%,-50%);
}
}

@media only screen and (max-width: 375px) and (max-height: 740px) {
    .card_center {
    font-size: 8px;
  }
}

@media only screen and (max-width: 375px) and (max-height: 680px) {
    .card_center {
    font-size: 8px;
  }
}
</style>
