<template>
  <div class="px-5">
    <template v-if="modelProxy[0].fabric_addition_id === null">
      <div class="w-100 d-flex flex-column justify-content-center items-center">
        <span
            class="d-inline-block py-2 my-5 text-md font-weight-bold">{{
            $t('order.do_you_want_add_extra_process')
          }}</span>
        <div class="row w-100 text-center d-flex justify-content-center">
          <button-with-icon v-for="(fabricAddition, key) in fabricAdditionOptions"
                            :active="true"
                            :icon-name="null"
                            :text="fabricAddition"
                            :key="key"
                            class="col-5 mx-1 my-1"
                            @onClick="changeFabricAdditionId(+key)"
          ></button-with-icon>
        </div>
      </div>
    </template>

    <template v-for="(currentDetail, index, i) in modelProxy"  v-if="modelProxy[0].fabric_addition_id !== null">
      <div :class="{'mt-5': +i !== 0}"
      :ref="`addition_${currentDetail.id}`"
      :id="`addition_${currentDetail.id}`"
      >
      <div class="d-flex navy-blue-border-bottom-1px px-2 py-2">
        <span class="h6 text-sm mr-2">
          {{$t('general.created_at')}}:</span><span class="text-sm">
            {{currentDetail.created_at?moment(currentDetail.created_at).format('DD.MM.YYYY hh:mm:ss'):moment().format('DD.MM.YYYY hh:mm:ss')}}</span>
        
        <span class="mx-1 h25"> / </span>
        <span class="h6 text-sm mr-2">
          {{$t('general.updated_date')}}:</span><span class="text-sm"> 
            {{currentDetail.updated_at?moment(currentDetail.updated_at).format('DD.MM.YYYY hh:mm:ss'):moment().format('DD.MM.YYYY hh:mm:ss')}}</span>
     </div>
        <div class="mb-7" style="overflow-x:auto;">
          <div class="d-flex">
          <div class="col-6 py-2 text-center top-1-items">
            {{ $t('order.supplier_company') }}
          </div>
          <div class="col-6 py-2 text-center top-1-items">
            {{ $t('order.dispatch_location') }}
          </div>
          <div class="col-6 py-2 text-center top-1-items">
            {{ $t('order.processes') }}
          </div>
        </div>
        <div class="d-flex">
          <div class="col-6 d-flex justify-content-center items-center py-2 text-center top-1-items">
            <custom-multi-select
                :has-bottom-margin="false"
                :item-per-row="1"
                :max="1"
                :disabled="currentDetail.lockable_company &&  isUserGranted('Order', ['updateInstructionSupplierCompany'],false,true)"
                :model.sync="currentDetail.supplier_company_id"
                :name="`order_fabric_extra_process_type_supplier_company_${index}`"
                :not-list="true"
                :options="supplierCompanyOptions"
                :required="false"
                :title="null"
                size="sm"
            ></custom-multi-select>
          </div>

          <div class="col-6 d-flex justify-content-center items-center py-2 text-center top-1-items">
            <custom-multi-select
                :has-bottom-margin="false"
                :is-right="true"
                :item-per-row="1"
                :model.sync="currentDetail.dispatch_location_ids"
                :name="`order_fabric_extra_process_type_dispatch_location_${index}`"
                :options="dispatchLocationOptions"
                :required="false"
                :title="null"
                size="sm"
                :max="1"
            ></custom-multi-select>
          </div>
          <div class="col-6 d-flex justify-content-center items-center py-2 text-center top-1-items">
            <custom-multi-select
                :has-bottom-margin="false"
                :item-per-row="1"
                :model.sync="currentDetail.fabric_process_ids"
                :name="`order_fabric_extra_process_type_fabric_process_${index}`"
                :options="fabricProcessOptions"
                :required="false"
                :title="null"
                size="sm"
            ></custom-multi-select>
          </div>
        </div>
        </div>

        <div class="d-flex">
          <div class="flex-1 py-2 text-center">
            {{ $t('order.painted_flow_date') }}
          </div>
          <div class="flex-1 py-2 text-center">
            {{ $t('order.painted_end_date') }}
          </div>
        </div>
        <div class="d-flex">
          <div
              class="col-6 d-flex justify-content-center items-center py-2 text-center top-1-items">
            <div>
              <date-picker-input
                  :has-bottom-margin="false"
                  :input-classes="'w-150px'"
                  :model.sync="currentDetail.painted_flow_date"
                  :name="`order_fabric_extra_process_type_painted_flow_date_${index}`"
                  :required="false"
                  :title="null"
              ></date-picker-input>
            </div>
          </div>
          <div class="col-6 d-flex justify-content-center items-center py-2 text-center top-1-items">
            <div>
              <date-picker-input
                  :has-bottom-margin="false"
                  :input-classes="'w-150px'"
                  :model.sync="currentDetail.painted_end_date"
                  :name="`order_fabric_extra_process_type_painted_end_date_${index}`"
                  :required="false"
                  :title="null"
              ></date-picker-input>
            </div>
          </div>
        </div>

        <fabric-detail-table-row
            :model.sync="currentDetail"
            :order-size-and-color-options="orderSizeAndColorOptions"
            :amount-unit-options="amountUnitOptions"
            @colorChanged="orderSizeAndColorSave($event, index)"
        ></fabric-detail-table-row>

        <test-method-row
            :product-processes.sync="currentDetail.product_processes"
            :selected-test-methods.sync="currentDetail.selectedProductProcesses"
            :test-method-options="productProcesses"
        ></test-method-row>

        <currency-table-row
            :currency-options="currencyOptions"
            :currency-options-raw="currencyOptionsRaw"
            :currency-rate-options="currencyRateOptions"
            :expiration-type-options="expirationTypeOptions"
            :model="currentDetail"
            :v-a-t-rate-options="VATRateOptions"
        ></currency-table-row>

        <div class="d-flex">
          <div class="flex-1 px-2 py-3">
            <textarea-input
                :is-valid="!!currentDetail.description"
                :model.sync="currentDetail.description"
                :name="`order_fabric_yarn_fabric_description_${index}`"
                :required="false"
                :title="$t('order.description')"
            ></textarea-input>
          </div>
        </div>
      </div>
      <span v-if="index > 0 && !currentDetail.id" class="cursor-pointer" @click="deleteNewDetail(index)" v-html="getIconByKey('icons.sample.delete_red',{
                        class: 'w-20px h-20px d-inline-block mr-3 '
                      })"></span>

      <span v-if="isValidToSubmit && index == modelProxy.length - 1"
            class="cursor-pointer"
            @click="addNewDetail"
            v-html="getIconByKey('icons.waybill.waybill_entry', {
            class: 'w-50px h50px d-line-block object-fill',
          })"
      ></span>

      <bottom-buttons
          :emails="[]"
          :is-editing.sync="currentDetail.is_editing"
          :is-valid-to-submit="isValidToSubmit(index)"
          :model="currentDetail"
          :order-id="orderId"
          :send-mail-url="sendMailUrl(index)"
          name="addition-request"
          @addNewDetail="addNewDetail"
          @onCancelInstruction="onCancelInstruction(currentDetail.id, index)"
          @submit="submitOrderFabricAddition(index)"
      ></bottom-buttons>
    </template>
  </div>
</template>

<script>
import TextareaInput from "@/assets/components/inputs/TextareaInput";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import DatePickerInput from "@/assets/components/inputs/DatePickerInput";
import TestMethodRow from "@/view/pages/order/layout/TestMethodRow";
import CurrencyTableRow from "@/view/pages/order/layout/CurrencyTableRow";
import FabricDetailTableRow from "@/view/pages/order/layout/FabricDetailTableRow";
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";
import _ from "lodash";
import BottomButtons from "@/view/pages/order/layout/BottomButtons";
import moment from "moment";
import {LARAVEL_DATE_TIME_FORMAT} from "@/core/config/constant";
import {CREATE_ITEM, DELETE_ITEM_BY_ID} from "@/core/services/store/REST.module";

export default {
  name: "OrderFabricExtraProcessTypeTable",
  components: {
    BottomButtons,
    ButtonWithIcon,
    FabricDetailTableRow, CurrencyTableRow, TestMethodRow, DatePickerInput, CustomMultiSelect, TextareaInput
  },
  props: {
    model: {
      required: true,
    },
    totalInstructions:{
      required:false,
    },
    maxPrice:{
      required: false,
    },
    maxPriceUnit:{
      required:false,
    },

    previousModel: {
      required: true,
    },
    yarnModel: {
      required: true,
    },
    defaultModel: {
      required: true,
    },
    orderId: {
      required: true,
      default: null,
    },
    amountUnitOptions: {
      required: true
    },
    order: {
      required: true,
      default: null
    },
    currentInstructionTypeId: {
      required: true,
    },
    fabricAdditionOptions: {
      required: true,
    },
    supplierCompanyOptions: {
      required: true,
    },
    fabricProcessOptions: {
      required: true,
    },
    dispatchLocationOptions: {
      required: true,
    },
    currencyOptions: {
      required: true,
    },
    expirationTypeOptions: {
      required: true,
    },
    VATRateOptions: {
      required: true,
    },
    currencyRateOptions: {
      required: true,
    },
    currencyOptionsRaw: {
      required: true
    },
    didChangeProcessingType: {
      required: true,
      type: Boolean,
    },
    productProcesses: {
      required: true,
    },
    orderFabricOptions: {
      required: true,
    },
    tolerances: {
      required: true,
    },
    fabricProcessingType: {
      required: true,
    },
    orderFabricId: {
      required: true,
    },
    orderSizeAndColorOptions: {
      required: true,
    }
  },
  data() {
    return {
      is_submitting: false,
    }
  },
  methods: {
    maxPriceCheck(){
      if(this.totalInstruction>this.maxPrice){
        return false;
      }
      return true;
    },

    orderSizeAndColorSave(selectedValue, index) {
      let model = this.modelProxy[index];
      let value = Number(selectedValue);
      let yarnModels = this.yarnModel.filter(item => +item.order_size_and_color_id === +value);
      let amount = 0;
      yarnModels.forEach(yarnModel => {
        amount += Number(yarnModel.amount);
      })

      model.amount = amount ? amount.toFixed(2) : 0;
    },
    onCancelInstruction(itemId, index) {
      const self = this;
      this.$store.dispatch(DELETE_ITEM_BY_ID, {
        url: `api/order/${this.orderId}/addition-request/${itemId}`
      }).then(function (response) {
        if (response.status) {
          self.sweetAlertSuccess(self.$t('general.successfully_deleted'));
          self.modelProxy.splice(index, 1);
          if (!self.modelProxy.length) {
            self.modelProxy = [self.defaultModel];
          }
        } else {
          self.sweetAlertError(self.$t('general.internal_error'));
        }
      });
    },
    deleteNewDetail(index) {
      this.modelProxy.splice(index, 1);
    },
    addNewDetail() {
      let copy = _.cloneDeepWith(this.defaultModel);
      copy.is_submitted = false;
      copy.is_editing = false;
      copy.fabric_addition_id = this.modelProxy[0].fabric_addition_id;
      copy.amount = 0;
      copy.id = null;

      let orderFabricId = this.orderFabricId;
      let fabricItem = this.orderFabricOptions.find(x => parseInt(x.id) === parseInt(orderFabricId));

      copy.width = fabricItem.width;
      copy.weight = fabricItem.weight;

      this.modelProxy.push(copy);
    },
    changeFabricAdditionId(value) {
      for (let key in this.model) {
        this.modelProxy[key].fabric_addition_id = +value;
      }
    },
    fixedData(index) {
      let items = [];
      let self = this;
      let item = this.modelProxy[index];
      item.dispatch_locations = item.dispatch_location_ids.map(location => {
        return {
          id: location,
        }
      });
      item.fabric_processes = item.fabric_process_ids.map(process => {
        return {
          id: process,
        };
      });
      item.order_fabric_id = self.orderFabricId;
      item.fabric_supply_type_id = self.fabricProcessingType;
      item.painted_flow_date = moment(item.painted_flow_date).format(LARAVEL_DATE_TIME_FORMAT);
      item.painted_end_date = moment(item.painted_end_date).format(LARAVEL_DATE_TIME_FORMAT);
      items.push(item);

      return items;
    },
    submitOrderFabricAddition(index) {
      let maxControl = this.maxPriceCheck(index);
      if(maxControl==false){
        this.sweetAlertError(this.$t('order.instruction_max_error'));
        return false;
      }

      if (this.is_submitting) return false;
      this.is_submitting = true;
      this.sweetAlertLoading();
      this.updateOrCreate(index);
    },
    updateOrCreate(index) {
      const self = this;
      self.$store.dispatch(CREATE_ITEM, {
        url: `api/order/${self.orderId}/addition-request`,
        contents: {
          order_addition_requests: self.fixedData(index),
        }
      }).then(response => {
        if (response.status) {
          let detail = self.modelProxy[index];
          let is_editing = !!detail.id;
          let responseData = response.data[0];
          detail.id = responseData.id
          detail.is_submitted = true;
          detail.is_editing = false;
          detail.pdf_link = responseData.pdf_link;
          detail.order_fabric_excel_link = responseData.order_fabric_excel_link
          self.modelProxy[index] = detail;
          self.sweetAlertSuccess(self.$t(is_editing ? 'general.successfully_updated' : 'general.successfully_created'));
        } else {
          let result = response.data.response;
          if (result.hasOwnProperty('data') && result.data.hasOwnProperty('message')) {
            self.sweetAlertError(result.data.message);
          } else {
            self.sweetAlertError(result.data);
          }
        }
        self.sweetAlertClose();
        self.is_submitting = false;
      });
    }
  },
  computed: {
    modelProxy: {
      moment: () => moment,
      get() {
        return this.model;
      },
      set(value) {
        this.$emit('update:modelProxy', value);
      }
    },
    isValidToSubmit() {
      try {
        let returnData = null;

        returnData = function (key) {
          const current = this.modelProxy[key];
          if (!+current.fabric_addition_id) return false;
          if (!+current.supplier_company_id) return false;
          if (!current.fabric_process_ids.length) return false;
          if (!current.dispatch_location_ids) return false;
          if (current.fabric_type === '' || current.fabric_type === null) return false;
          if (current.color === '' || current.color === null) return false;
          if (current.color_ok === '' || current.color_ok === null) return false;
          if (current.weight === '' || current.weight === null) return false;
          if (!+current.width) return false;
          if (current.selectedProductProcesses.length === 0) return false;
          if (!+current.currency_unit_id) return false;
          if (!+current.order_size_and_color_id) return false;
          if (!+current.expiry_id) return false;
          if (!+current.unit_price) return false;
          if (!+current.vat_rate_id) return false;
          if (current.description === null || current.description === '') return false;
          return true;
        }

        return returnData;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    sendMailUrl() {
      try {
        let returnData = null;
        returnData = function (key) {
          const itemId = this.modelProxy[key].id;
          return `api/order/${this.orderId}/addition-request/${itemId}/share`
        }
        return returnData;
      } catch (e) {
        console.error(e);
        return false;
      }
    }
  },
  mounted() {
    if (this.yarnModel && this.yarnModel.length > 0) {
      this.modelProxy.forEach(model => {
        let orderFabricId = this.orderFabricId;
        model.fabric_supply_type_id = this.fabricProcessingType;
        model.tolerance_amount = this.maxTolerance;
        model.order_fabric_id = this.orderFabricId;

        let fabricItem = this.orderFabricOptions.find(x => parseInt(x.id) === parseInt(orderFabricId));

        if (!model.width) {
          model.width = fabricItem.width;
        }
        if (!model.weight) {
          model.weight = fabricItem.weight;
        }

        if (!+model.supplier_company_id) {
          model.supplier_company_id = this.previousModel[0].supplier_company_id;
        }
      })
    }
    if(this.$route.query.qr_type=="addition-request"){
      setTimeout(() => {
        let instructionsId = this.$route.query.data.id
        let element = this.$refs["addition_"+instructionsId][0]
        if(element){
          element.scrollIntoView({behavior: "smooth"})
        }
      }, 2000);
    }
  },
  watch: {
    modelProxy: {
      handler(newValue, oldValue) {
        if (newValue) {
          let totalAmount = _.sumBy(this.modelProxy, 'amount');
          newValue.forEach(model => {
            model.fabric_supply_type_id = this.fabricProcessingType;
            model.tolerance_amount = this.tolerances.additional_processing_coefficient;
            model.order_fabric_id = this.orderFabricId;
            if (!model.amount) {
              model.amount = _.sumBy(this.yarnModel, 'amount') - totalAmount;
            }
          })
        }
      },
      deep: true
    },
    orderFabricId(value) {
      this.modelProxy.forEach(model => {
        model.order_fabric_id = value;
        if (!model.amount) {
          model.amount = _.sumBy(this.yarnModel, 'amount');
        }
      })
    },
    tolerances(value) {
      if (value) {
        this.modelProxy.forEach(model => {
          model.tolerance_amount = this.tolerances ? this.tolerances.additional_processing_coefficient : 1;
        })
      }
    }
  }
}
</script>

<style lang="scss">
/*Mobile Start*/
@import "@/assets/sass/mobile/order/orderFabricExtraProcessTypeTable.scss";
/*Mobile End*/
</style>
