<template>
  <div class="row">
    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 py-3" v-for="item in task">
      <div class="card card-custom card-border cursor-pointer card-with"
           @click="$emit('click', {task: item, type: 2})"
           style="box-shadow: -5px -5px rgba(100, 100, 100, 0.1), 5px 5px rgba(100, 100, 100, 0.1) !important; background-color: rgb(245, 245, 245) !important;">
        <div class="card-header">
          <div class="card-title">
            <h2 class="card-label w-auto">
              {{ item.order_number }}
            </h2>
          </div>
        </div>
        <div class="card-body">
          <div class="d-flex justify-content-start">
             <p class="flex-40"><b> {{ $t('packing_list.supplier_company_name') }} : </b>
             <p class="text-right px-2 font-weight-bolder"></p>
             <p class="pl-3 flex-60">
               {{ item.supplier_company_name }}
             </p>
          </div>
          <div class="d-flex justify-content-start">
            <p class="flex-40"><b> {{ $t('general.created_by') }} : </b>
            <p class="text-right px-2 font-weight-bolder"></p>
            <p class="pl-3 flex-60">
              {{ item.user }}
            </p>
          </div>

          <div class="d-flex justify-content-between">
            <p class="flex-40"><b> {{ $t('packing_list.date') }} : </b>
            <p class="text-right px-2 font-weight-bolder"></p>
            <p class="pl-3 flex-60">
              {{ item.created_at }}
            </p>
          </div>

          <div class="d-flex justify-content-between">
            <p class="flex-40"><b> {{ $t('packing_list.in_package') }} : </b>
            <p class="text-right px-2 font-weight-bolder"></p>
            <p class="pl-3 flex-60">
              {{ item.in_package }}
            </p>
          </div>

          <div class="d-flex justify-content-between">
            <p class="flex-40"><b> {{ $t('packing_list.order_po_number') }} : </b>
            <p class="text-right px-2 font-weight-bolder"></p>
            <p class="pl-3 flex-60">
              {{ item.po_number }}
            </p>
          </div>

          <div class="d-flex justify-content-between">
            <p class="flex-40"><b> {{ $t('packing_list.order_model') }} : </b>
            <p class="text-right px-2 font-weight-bolder"></p>
            <p class="pl-3 flex-60">
              {{ item.model }}
            </p>
          </div>

          <div class="d-flex justify-content-between">
            <p class="flex-40"><b>{{ $t('packing_list.box_quantity') }} : </b>
            <p class="text-right px-2 font-weight-bolder"></p>
            <p class="pl-3 flex-60">
              {{ item.box_quantity }}
            </p>
          </div>

          <div class="d-flex justify-content-between">
            <p class="flex-40"><b>{{ $t('packing_list.total_amount') }} : </b>
            <p class="text-right px-2 font-weight-bolder"></p>
            <p class="pl-3 flex-60">
              {{ item.total_amount }}
            </p>
          </div>

          <div class="d-flex justify-content-between">
            <p class="flex-40"><b>{{ $t('packing_list.request_quantity') }} : </b>
            <p class="text-right px-2 font-weight-bolder"></p>
            <p class="pl-3 flex-60">
              {{ item.amount }}
            </p>
          </div>
          <div class="d-flex justify-content-between">
            <p class="flex-40"><b>{{ $t('packing_list.color_name') }} : </b>
            <p class="text-right px-2 font-weight-bolder"></p>
            <p class="pl-3 flex-60">
              {{ item.order_size_and_colors }} 
            </p>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "PackingTaskCard",
  data() {
    return {
      today: moment(),
    }
  },
  props: {
    task: {
      required: true,
    },
    type: {
      required: true,
    }
  }
}
</script>

<style scoped type="scss">
.card-with {
  width: auto;
}

.object-fit-cover {
  object-fit: cover !important;
}

.card-header {
  padding: 2rem 2.25rem 0 1rem;
  background-color: #ffffff;
  border-bottom: 0px;
}

.card.card-custom > .card-body {
  padding: 0rem 0.55rem 0.65rem 0.55rem;
}

.card-custom {
  border-radius: 20px !important;
  border: 3px solid $primary-gray !important;
  box-shadow: 0.5px 1px $primary-gray-shadow !important;
}

.card {

}
</style>
